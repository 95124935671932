import { ROUTES } from '../components/sidebar/sidebar-constants';
import { authService } from '../dar-dms-topbar';
import { environment } from '../environments/environment';
import { httpClient } from './clients';

export type ModuleSetting = {
	moduleType: ROUTES;
	order: number;
};

type GetPinnedItemsResponse = {
	id: string;
	employeeId: string;
	workspaceId: string;
	modulesSettings: readonly ModuleSetting[];
	updatedAt: string;
	createdAt: string;
};

export const getPinnedMenuItems = () => {
	const workspaceToken = authService.getWorkspaceToken();

	return httpClient.get<GetPinnedItemsResponse>(`${environment().hcmsApiRoot}/main-api/sidebar/settings`, {
		headers: {
			'Workspace-Authorization': workspaceToken,
		},
	});
};

export const updatePinnedMenuItems = (modulesSettings: readonly ModuleSetting[]) => {
	const workspaceToken = authService.getWorkspaceToken();

	return httpClient.put<any[]>(
		`${environment().hcmsApiRoot}/main-api/sidebar/settings`,
		{
			modulesSettings,
		},
		{
			headers: {
				'Workspace-Authorization': workspaceToken,
			},
		}
	);
};
