import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Menu, MenuItem, Typography, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
	option: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'&:hover': {
			background: 'rgba(38, 40, 66, 0.04)',
		},
		'&.Mui-selected': {
			background: 'rgba(38, 40, 66, 0.08)',
			'&:hover': {
				background: 'rgba(38, 40, 66, 0.04)',
			},
		},
	},
}));

const LanguageMenu = ({ anchorEl, onClose, onSelect, langs }) => {
	const languageMenuOpen = Boolean(anchorEl);
	const classes = useStyles();
	const {
		i18n: { language },
	} = useTranslation();

	const defaultLanguage = useMemo(() => langs.find((item) => item.id.includes(language)), [language, langs]);

	return (
		<Menu
			id='menu-languages'
			disableEnforceFocus
			disableRestoreFocus
			anchorEl={anchorEl}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open={languageMenuOpen}
			onClose={onClose}
			MenuListProps={{
				style: { padding: '8px 0', width: '100%' },
			}}
			PaperProps={{
				style: {
					borderRadius: '0px 0px 4px 4px',
					border: 'none',
					width: '200px',
					zIndex: 1200,
					transform: 'translate(105%, 15%)',
				},
			}}
			defaultValue={defaultLanguage?.id}
		>
			{langs.map((item, index) => (
				<MenuItem
					key={index}
					value={item?.id}
					className={classes.option}
					selected={item?.id == language}
					onClick={() => onSelect(item?.id)}
				>
					<Typography
						style={{
							fontSize: '14px',
							lineHeight: '18px',
							fontWeight: 400,
						}}
					>
						{item?.name}
					</Typography>
					{defaultLanguage?.id === item?.id && <CheckIcon style={{ color: '#6D7C8B', marginRight: '9px' }} />}
				</MenuItem>
			))}
		</Menu>
	);
};

export { LanguageMenu };
