import React, { ReactElement } from 'react';

import styled from '@emotion/styled';
import { Box, BoxProps } from '@material-ui/core';
import { flexCenterStyles } from '@topbar/src/components/sidebar/utils/styles';

import { TypographyVariant, sidebarTheme } from '../../../sidebar-theme';

const NotificationContainer = styled(Box)<{ $counter: number }>`
	${flexCenterStyles}

	background: #f0f0f7;
	border-radius: 50%;
	color: #262842;
	font-family: Euclid;
	font-size: ${sidebarTheme.fontSize[TypographyVariant.SMALL]};
	line-height: ${sidebarTheme.lineHeight[TypographyVariant.SMALL]};
	min-height: ${(props) => (props.$counter > 99 ? '30px' : props.$counter > 9 ? '26px' : '20px')};
	min-width: 20px;
	padding: 0px ${sidebarTheme.spacing['positive-75']};
	text-align: center;
`;

type NotificationCounterProps = Pick<BoxProps, 'mr' | 'ml' | 'mt' | 'mb'> & {
	counter: number;
};

const MAX_COUNTER_TO_DISPLAY = 99;

export function NotificationCounter({ counter, ...rest }: NotificationCounterProps): ReactElement {
	return (
		<NotificationContainer {...rest} $counter={counter}>
			<span>{counter > MAX_COUNTER_TO_DISPLAY ? `+${MAX_COUNTER_TO_DISPLAY}` : counter}</span>
		</NotificationContainer>
	);
}
