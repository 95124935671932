import { t } from 'i18next';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Nav } from './nav';
import { PlanSettingsRoutes } from './utils';

const PlanSettingsNav = () => {
	const { pathname } = useLocation();
	const backPath = pathname
		.split('/')
		.filter((_, ind) => ind < 4)
		.join('/');
	return (
		<Nav
			tab='plans'
			routes={PlanSettingsRoutes}
			variant='light'
			backPath={backPath}
			backText={t('projectNav.back_to_projects')}
		/>
	);
};

export { PlanSettingsNav };
