const regions = ['kz', 'id', 'com'];
export const domainInfo = regions.reduce(
	(acc, v) => {
		acc[`is${v.toUpperCase()}`] = () => window.origin.endsWith(v);
		return acc;
	},
	{
		isDarIo: () => window.origin.endsWith('dar.io'),
	}
) as Record<'isKZ' | 'isID' | 'isCOM' | 'isDarIo', () => boolean>;
