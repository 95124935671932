import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { EventEmitter } from 'events';
import { BehaviorSubject } from 'rxjs';

import {
	AuthSubjectValue,
	AuthTokens,
	CheckUserExistsRequest,
	EmailVerificationResponse,
	LoginPayloadType,
	PasswordType,
	Profile,
	ProfileUpdateDTO,
	RegisterPayloadType,
	RegisterResponse,
	SendCodeType,
	SendOtpResponse,
	SignUpResponse,
	SignupType,
} from './types';

export enum AuthStatus {
	UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface AuthConfig {
	griffonApiRoot: string;
	griffonClientId: string;
	griffonClientSecret: string;
	dmsSettingsApiUrl: string;
	dmsLandingApiUrl: string;
}

export const TOKEN_STORAGE_KEY = 'dms-auth';
export const PROFILE_STORAGE_KEY = 'profile';
export const WORKSPACE_STORAGE_KEY = 'workspace';
export const WORKSPACE_TOKEN_STORAGE_KEY = 'workspace_token';
export const NEW_WORKSPACE_EMAIL_KEY = 'new_workspace_contact_email';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const FormData = require('form-data');

const getAuthSubjectValueFromStorage = (): AuthSubjectValue | null => {
	const _profile = localStorage.getItem(PROFILE_STORAGE_KEY);
	const _tokens = localStorage.getItem(TOKEN_STORAGE_KEY);

	if (!_tokens || !_profile) {
		return null;
	}

	try {
		const profile = JSON.parse(_profile);
		const tokens = JSON.parse(_tokens);
		return {
			profile,
			tokens,
		};
	} catch (e) {
		return null;
	}
};

const getGriffonLang = (lang: string) => {
	switch (lang) {
		case 'ru':
		case 'kz':
			return 'ru';
		case 'en':
		default:
			return 'en-US';
	}
};

export class AuthService {
	httpClient: AxiosInstance;

	private tokenRefreshing: Promise<void>;

	public loginStatus = new EventEmitter();

	private loggedIn = new BehaviorSubject<AuthSubjectValue | null>(getAuthSubjectValueFromStorage());
	public loggedIn$ = this.loggedIn.asObservable();
	public loggedInValue = this.loggedIn.getValue();
	public unauthorizedRoutes = ['/productivity/meetings/room/', '/productivity/meetings/static/'];
	constructor(private config: AuthConfig) {
		this.httpClient = axios.create();

		this.httpClient.interceptors.response.use((response: AxiosResponse) => response, this.refreshTokenErrorInterceptor);
		this.httpClient.interceptors.request.use(this.authRequestInterceptor);
	}

	public setLoggedInValue = (v: AuthSubjectValue | null) => {
		this.loggedIn.next(v);
	};

	static createInstance(config: AuthConfig) {
		return new AuthService(config);
	}

	public createHttpClient(axiosConfig?: AxiosRequestConfig): AxiosInstance {
		const httpClient = axios.create(axiosConfig);
		httpClient.interceptors.request.use(this.authTokenRequestInterceptor);
		httpClient.interceptors.response.use((response: AxiosResponse) => response, this.refreshTokenErrorInterceptor);
		return httpClient;
	}

	private authRequestInterceptor = async (config: AxiosRequestConfig) => {
		config.headers['accept-language'] = getGriffonLang(localStorage.getItem('i18nextLng'));
		return config;
	};

	private authTokenRequestInterceptor = async (config: AxiosRequestConfig) => {
		config.headers['accept-language'] = localStorage.getItem('i18nextLng');

		if (config.headers?.['Authorization'] && config.headers?.['Workspace-Authorization']) {
			// Workspace-Authorization header is not used right now and causes CORS error
			delete config.headers?.['Workspace-Authorization'];
			return config;
		}
		if (this.tokenRefreshing) {
			await this.tokenRefreshing;
		}
		const tokens = this.getTokens();
		const now = new Date();
		// Token is expired
		if (!tokens || (tokens.expire_date && tokens.expire_date - now.getTime() <= 0)) {
			this.tokenRefreshing = new Promise((resolve) => {
				this.refreshToken()
					.then((newTokens) => {
						this.persistTokens(newTokens);
					})
					.catch(() => {
						this.loginStatus.emit(AuthStatus.UNAUTHORIZED, true);
						this.loggedIn.next(null);
					})
					.finally(() => {
						resolve();
						this.tokenRefreshing = null;
					});
			});
		}

		// Add token to headers
		const idToken = this.getIdToken();
		// const workspaceToken = this.getWorkspaceToken();
		if (idToken) {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${idToken}`,
			};
		} else {
			delete config.headers?.['Authorization'];
		}
		// if (workspaceToken) {
		// 	config.headers = {
		// 		...config.headers,
		// 		'Workspace-Authorization': workspaceToken,
		// 	};
		// } else {
		// 	delete config.headers?.['Workspace-Authorization'];
		// }

		return config;
	};

	public getWorkspaceToken = (): string | null => {
		let workspaceToken: string | null = null;
		try {
			workspaceToken = localStorage.getItem(WORKSPACE_TOKEN_STORAGE_KEY);
		} catch (e) {
			console.error(e);
		}
		return workspaceToken ?? null;
	};

	public getIdToken = (): string | null => {
		let tokens: AuthTokens | null = null;
		try {
			tokens = JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY) || null);
		} catch (e) {
			console.error(e);
		}
		return tokens?.id_token ?? null;
	};

	public persistProfile = (profile: Profile) => {
		localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(profile));
	};

	public refreshProfile = (profile: Profile) => {
		const tokens = this.getTokens();
		this.persistTokens(tokens);
		this.persistProfile(profile);
		this.setLoggedInValue({ profile, tokens });
	};
	public persistTokens = (tokens: Partial<AuthTokens>) => {
		const expire_date = new Date().getTime() + tokens.expires_in * 1000;
		localStorage.setItem(
			TOKEN_STORAGE_KEY,
			JSON.stringify({
				...tokens,
				expire_date,
			})
		);
	};

	public getTokens = (): AuthTokens | null => {
		let tokens: AuthTokens | null = null;
		try {
			tokens = JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY));
		} catch (e) {
			console.error(e);
		}
		return tokens;
	};

	private refreshTokenErrorInterceptor = (axiosError: AxiosError) => {
		if (axiosError.response?.status === 401) {
			return this.refreshToken()
				.then((authInfo) => {
					this.persistTokens(authInfo);
					axiosError.config.headers = {
						...axiosError.config.headers,
						Authorization: `Bearer ${authInfo.id_token}`,
					};
					return axios.request(axiosError.config);
				})
				.catch(() => {
					this.loginStatus.emit(AuthStatus.UNAUTHORIZED, true);
					this.logout();
					this.loggedIn.next(null);
				});
		}
		return Promise.reject(axiosError.response?.data);
	};

	public login = ({ password, username }: LoginPayloadType) => {
		const params = new URLSearchParams({
			client_id: this.config.griffonClientId,
			client_secret: this.config.griffonClientSecret,
			grant_type: 'password',
			username,
			password,
		});
		return axios.post<AuthTokens>(`${this.config.griffonApiRoot}/oauth/token`, params).then((res) => {
			if (!res) {
				throw new Error('Data is empty');
			}
			return res?.data;
		});
	};

	public loginWithCode = ({ code }: { code: string }) => {
		const params = new URLSearchParams({
			client_id: this.config.griffonClientId,
			client_secret: this.config.griffonClientSecret,
			grant_type: 'code',
			code,
		});
		return axios.post<AuthTokens>(`${this.config.griffonApiRoot}/oauth/token`, params).then((res) => res.data);
	};

	public refreshToken = () => {
		const params = new URLSearchParams();
		params.append('client_id', this.config.griffonClientId);
		params.append('client_secret', this.config.griffonClientSecret);
		params.append('refresh_token', this.getTokens()?.refresh_token || '');
		params.append('grant_type', 'refresh_token');

		return axios.post<AuthTokens>(`${this.config.griffonApiRoot}/oauth/token`, params).then((res) => res.data);
	};

	public refreshTokenAndProfile = () => {
		return this.refreshToken().then(async (authInfo) => {
			this.persistTokens(authInfo);
			const profile = await this.getProfile(authInfo.id_token);
			this.persistProfile(profile);
			this.setLoggedInValue({ profile, tokens: authInfo });
			return authInfo;
		});
	};

	public sendOtp = ({ code, sid }: SendCodeType) => {
		return this.httpClient
			.post<SendOtpResponse>(
				`${this.config.griffonApiRoot}/oauth/signup/verify`,
				{
					code,
				},
				{
					params: {
						sid,
					},
				}
			)
			.then((res) => res.data);
	};

	public reSendOtp = (sid: string) => {
		return this.httpClient
			.post<SendOtpResponse>(`${this.config.griffonApiRoot}/oauth/signup/resend`, null, {
				params: {
					sid,
				},
			})
			.then((res) => res.data);
	};

	public signUp = ({ username, state }: SignupType) => {
		const params = new URLSearchParams();
		params.append('client_id', this.config.griffonClientId);
		params.append('client_secret', this.config.griffonClientSecret);
		params.append('username', username);
		params.append('state', state);
		return this.httpClient.post<SignUpResponse>(`${this.config.griffonApiRoot}/oauth/signup`, params).then((res) => res.data);
	};

	public signUpNoOTP = (params: { email: string; password: string; phoneNumber: string; region: string }) => {
		return this.httpClient
			.post<SignUpResponse>(`${this.config.dmsLandingApiUrl}/api/auth/register`, params)
			.then((res) => res.data);
	};

	public register = ({ password, sid }: RegisterPayloadType) => {
		return this.httpClient
			.post<RegisterResponse>(
				`${this.config.griffonApiRoot}/oauth/register`,
				{
					password,
				},
				{
					params: {
						sid,
					},
				}
			)
			.then((res) => res.data);
	};

	public getProfile = (id_token: string) => {
		return this.httpClient
			.get<Profile>(`${this.config.griffonApiRoot}/oauth/profile`, {
				headers: { Authorization: `Bearer ${id_token}` },
			})
			.then((res) => (!res.data.default_page ? { ...res.data, default_page: '/overview' } : res.data));
	};

	public updateGriffonProfile = (data: ProfileUpdateDTO) => {
		return this.httpClient
			.put<Profile>(`${this.config.griffonApiRoot}/oauth/profile`, data, {
				headers: {
					Authorization: `Bearer ${this.getIdToken()}`,
				},
			})
			.then((res) => res.data);
	};

	public sendEmailVerificationCode = (email: string, password: string) => {
		return axios
			.post<EmailVerificationResponse>(
				`${this.config.griffonApiRoot}/oauth/user/email/code`,
				{ email, password },
				{
					headers: {
						Authorization: `Bearer ${this.getIdToken()}`,
					},
				}
			)
			.then((res) => res.data);
	};

	public verifyUpdatedEmailCode = (verification_token: string, code: string) => {
		return axios
			.get(`${this.config.griffonApiRoot}/oauth/user/email/code/${verification_token}`, {
				params: {
					code,
				},
			})
			.then((res) => res.data);
	};

	public updateProfile = (data: ProfileUpdateDTO) => {
		return this.httpClient
			.put<Profile>(`${this.config.dmsSettingsApiUrl}/general/profile`, data, {
				headers: {
					Authorization: `Bearer ${this.getIdToken()}`,
				},
			})
			.then((res) => res.data);
	};
	public deleteAvatar = () => {
		return this.httpClient
			.delete(`${this.config.dmsSettingsApiUrl}/general/avatar`, {
				headers: {
					Authorization: `Bearer ${this.getIdToken()}`,
				},
			})
			.then((res) => res.data);
	};

	public uploadAvatar = (file) => {
		const formData = new FormData();
		formData.append('avatar', file, 'image.png');
		formData.get('avatar');
		return this.httpClient
			.post(`${this.config.dmsSettingsApiUrl}/general/avatar`, formData, {
				headers: {
					Authorization: `Bearer ${this.getIdToken()}`,
				},
			})
			.then((res) => res.data);
	};

	public createProfile = (data: ProfileUpdateDTO, id_token: string) => {
		return axios
			.post<Profile>(`${this.config.griffonApiRoot}/oauth/profile`, data, {
				headers: {
					Authorization: `Bearer ${id_token ?? this.getIdToken()}`,
				},
			})
			.then((res) => res.data);
	};
	public logOutAllSessions = () => {
		return this.httpClient
			.post(`${this.config.dmsSettingsApiUrl}/security/session`, {
				headers: {
					Authorization: `Bearer ${this.getIdToken()}`,
				},
			})
			.then((res) => res.data);
	};
	public updatePassword = (data) => {
		return this.httpClient
			.post(`${this.config.dmsSettingsApiUrl}/security/password`, data, {
				headers: {
					Authorization: `Bearer ${this.getIdToken()}`,
				},
			})
			.then((res) => res.data);
	};
	public sendRestorePassword = (username: string) => {
		return this.httpClient
			.post<SignUpResponse>(`${this.config.griffonApiRoot}/oauth/password/reset`, {
				client_id: this.config.griffonClientId,
				username,
			})
			.then((res) => ({ ...res.data, username }));
	};

	public verifyRestoreSecret = (sid: string, code: string) => {
		return this.httpClient
			.post<SendOtpResponse>(
				`${this.config.griffonApiRoot}/oauth/password/reset/verify`,
				{},
				{
					params: {
						sid,
						code,
					},
				}
			)
			.then((res) => res.data);
	};

	public setResetPassword = (sid: string, new_password: string) => {
		return this.httpClient
			.put(
				`${this.config.griffonApiRoot}/oauth/password/reset`,
				{ new_password },
				{
					params: {
						sid,
					},
				}
			)
			.then((res) => res.data);
	};

	public resendResetPassword = (sid: string) => {
		return this.httpClient.post(`${this.config.griffonApiRoot}/oauth/password/reset/resend?sid=${sid}`).then((res) => res.data);
	};

	public checkIfUserExists = (data: CheckUserExistsRequest) => {
		return this.httpClient.post<any>(`${this.config.griffonApiRoot}/oauth/signup/check`, data).then((res) => res.status);
	};

	public getPolicy = () => {
		const params = {
			client_id: this.config.griffonClientId,
			client_secret: this.config.griffonClientSecret,
		};
		return this.httpClient.get(`${this.config.griffonApiRoot}/mgmt/helpers/app-info`, { params }).then((res) => res.data);
	};

	public logout = () => {
		localStorage.removeItem(PROFILE_STORAGE_KEY);
		localStorage.removeItem(TOKEN_STORAGE_KEY);
		localStorage.removeItem(WORKSPACE_STORAGE_KEY);
		localStorage.removeItem(WORKSPACE_TOKEN_STORAGE_KEY);
		localStorage.removeItem(NEW_WORKSPACE_EMAIL_KEY);
	};

	public sendResetCode = ({ code, sid }: SendCodeType) => {
		return this.httpClient
			.post<SendOtpResponse>(`${this.config.griffonApiRoot}/oauth/password/reset/verify`, undefined, {
				params: {
					sid,
					code,
				},
			})
			.then((res) => res.data);
	};

	public updateResetPassword = ({ password, sid }: RegisterPayloadType) => {
		return this.httpClient
			.put<PasswordType>(
				`${this.config.griffonApiRoot}/oauth/password/reset`,
				{
					new_password: password,
				},
				{
					params: {
						sid,
					},
				}
			)
			.then((res) => ({ password }));
	};
	public updatePasswordNew = ({ old_pass, new_pass, sid }) => {
		return this.httpClient.post<PasswordType>(
			`${this.config.griffonApiRoot}/oauth/new-password`,
			{
				new_pass,
				old_pass,
			},
			{
				params: {
					sid,
				},
			}
		);
	};
}
