import * as React from 'react';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgPeopleTeamRegular({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				d='M12.295 8.333c.805 0 1.458.654 1.458 1.459v3.957a3.75 3.75 0 11-7.501 0V9.792c0-.805.652-1.459 1.458-1.459h4.585zm0 1.25H7.71a.208.208 0 00-.208.209v3.957a2.5 2.5 0 005.001 0V9.792a.208.208 0 00-.208-.209zm-9.17-1.25h2.818c-.294.355-.474.791-.515 1.25H3.125a.208.208 0 00-.208.209v2.707a2.084 2.084 0 002.568 2.028c.07.42.2.82.377 1.193a3.335 3.335 0 01-4.195-3.22V9.791c0-.805.653-1.459 1.458-1.459zm10.938 0h2.812c.805 0 1.458.654 1.458 1.459V12.5a3.332 3.332 0 01-4.191 3.222 4.54 4.54 0 00.379-1.194 2.085 2.085 0 002.562-2.028V9.792a.208.208 0 00-.208-.209h-2.297a2.281 2.281 0 00-.515-1.25zM10 2.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm5.417.833a2.083 2.083 0 110 4.167 2.083 2.083 0 010-4.167zm-10.834 0a2.083 2.083 0 110 4.167 2.083 2.083 0 010-4.167zM10 3.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm5.417.833a.833.833 0 100 1.667.833.833 0 000-1.667zm-10.834 0a.833.833 0 100 1.667.833.833 0 000-1.667z'
				fill='#262842'
			/>
		</svg>
	);
}

const MemoSvgPeopleTeamRegular = React.memo(SvgPeopleTeamRegular);
export default MemoSvgPeopleTeamRegular;
