import cx from 'classnames';
import React from 'react';

import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '45px',
		width: '45px',
		margin: '0 auto',

		'&.fullscreen': {
			background: 'rgba(255, 255, 255, 0.5)',
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100vw',
			height: '100vh',
			zIndex: 9999,
		},

		'&.absolute': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			background: 'rgba(255, 255, 255, 0.5)',
			zIndex: 10,
		},
	},
	circularProgress: {
		color: '#8B8C9E',
	},
}));

type Props = {
	fullscreen?: boolean;
	size?: number;
	absolute?: boolean;
};

const SpinnerComp: React.FC<Props> = ({ fullscreen = false, size = 32, absolute = false }) => {
	const classes = useStyles();

	return (
		<div
			className={cx(classes.container, {
				fullscreen,
				absolute,
			})}
		>
			<CircularProgress size={size} className={classes.circularProgress} />
		</div>
	);
};

export const Spinner = React.memo(SpinnerComp);
