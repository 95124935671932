import * as React from 'react';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgBookSearchFilled({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				d='M9.77 6.206a1.897 1.897 0 100 3.794 1.897 1.897 0 000-3.794zM3.333 3.75a2.083 2.083 0 012.084-2.083H15a2.083 2.083 0 012.083 2.083v11.875a.625.625 0 01-.625.625H4.583a.833.833 0 00.834.833h11.041a.625.625 0 010 1.25H5.417a2.083 2.083 0 01-2.084-2.083V3.75zm9.016 6.08a3.103 3.103 0 10-.853.852l2.063 2.063.056.048.007.005a.603.603 0 00.785-.91l-2.059-2.059h.001z'
				fill='#262842'
			/>
		</svg>
	);
}

const MemoSvgBookSearchFilled = React.memo(SvgBookSearchFilled);
export default MemoSvgBookSearchFilled;
