import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Workspace } from '@dar/api-interfaces';
import { WORKSPACE_TOKEN_STORAGE_KEY } from '@dartech/griffon-auth';
import { Avatar, Badge, Box, Theme, Typography, makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { MATOMO_KEYS } from '@topbar/src/constants/matomo';
import { WORKSPACE_STORAGE_KEY } from '@topbar/src/constants/workspace';
import { useWorkspace } from '@topbar/src/hooks/useCompanyID';
import { getEmployeeWorkspaces } from '@topbar/src/services/companies';
import { getLanguage } from '@topbar/src/services/language';
import { notificationsService } from '@topbar/src/services/notifications';
import { getWorkspaceToken } from '@topbar/src/services/workspace';
import { avatarBackgroundColors, pickNumberFromString } from '@topbar/src/shared/utils';

import { ReactComponent as RightArrowIcon } from '../../../assets/icons/arrow_right.svg';
import { useAuth } from '../../../topbar/providers/auth-provider';
import useStyles from '../style';
import { takeShortName } from '../utils';
import { WorkspacesMenu } from './workspaces-menu';

interface Props {
	isMini: boolean;
	workspace: Workspace;
	variant: 'light' | 'dark';
}

const useOwnStyles = makeStyles<Theme, { variant: 'light' | 'dark' }>((theme) => ({
	root: {
		width: '32px',
		height: '32px',
		fontSize: '14px',
		background: 'rgba(255, 255, 255, 0.12)',
		border: '2px solid rgba(255, 255, 255, 0.12)',
		borderRadius: '6px',
	},
	smallAvatar: {
		width: '24px',
		height: '24px',
		fontSize: '12px',
		background: (props) => (props.variant === 'light' ? 'rgba(38, 40, 66, 0.08)' : 'rgba(255, 255, 255, 0.12)'),
		border: (props) => (props.variant === 'light' ? '2px solid rgba(38, 40, 66, 0.12)' : '2px solid rgba(255, 255, 255, 0.12)'),
		borderRadius: '6px',
	},
	totalCounter: {
		marginLeft: 'auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: '20px',
		maxWidth: '26px',
		height: '20px',
		padding: '0px 6px',
		borderRadius: '12px',
		background: '#E94E3A',
		color: '#F7F8FC',
		fontSize: '12px',
		lineHeight: '12px',
	},
	arrow: {
		'& > path': {
			fill: (props) => (props.variant === 'light' ? '#8B8C9E' : '#fff'),
		},
	},
}));

export const useBadgeStyles = makeStyles((theme) => ({
	badge: {
		border: '1px solid #fff',
		backgroundColor: '#E94E3A',
	},
}));

const TIMESHEET_REDIRECT_URL = '/people/timesheets';
const WORKSPACES_TO_SHOW = 4;
const relocate = ['upgrade', 'forbidden', '/productivity/meetings/room', '/productivity/meetings/static'];

const Header = ({ isMini, workspace: activeWorkspace, variant }: Props) => {
	const { t } = useTranslation();
	const classes = useStyles({ isMini, variant });
	const ownClasses = useOwnStyles({ variant });
	const badgeStyles = useBadgeStyles();
	const { profile, logout, setWorkspace } = useAuth();
	const history = useHistory();
	const workspace = useWorkspace();

	const [workspaceAnchorEl, setWorkspaceAnchorEl] = useState<null | HTMLElement>(null);
	const workspaceMenuOpen = Boolean(workspaceAnchorEl);

	const { data } = useQuery('fetch-workspaces', () => getEmployeeWorkspaces(profile?.id), {
		onError: () => {
			logout();
		},
		enabled: !!profile?.id,
	});
	const holdings = useMemo(() => (data?.length ? [...new Set(data?.filter((v) => v.holdingId).map((v) => v.holdingId))] : []), [
		data,
	]);

	const { data: counters } = useQuery({
		queryKey: 'holdingsNotificationsCounters',
		queryFn: () => notificationsService?.getNotificationsCountersByHoldings(holdings),
		refetchInterval: 10000,
		enabled: !!holdings?.length,
	});

	const { isLoading: isLanguageLoading } = useQuery('get-language', () => getLanguage(profile?.id), { enabled: !!profile?.id });

	const countersByWorkspaceId = useMemo(() => {
		if (counters?.workspaces?.length) {
			return counters.workspaces.reduce((acc, val) => {
				return { ...acc, [val.companyId]: val.total };
			}, {});
		}
		return {};
	}, [counters]);

	const sortedWorkspaces = useMemo(() => {
		if (countersByWorkspaceId && data?.length) {
			data.sort((a, b) => {
				const aValue = countersByWorkspaceId[a.id] !== undefined ? countersByWorkspaceId[a.id] : Number.NEGATIVE_INFINITY;
				const bValue = countersByWorkspaceId[b.id] !== undefined ? countersByWorkspaceId[b.id] : Number.NEGATIVE_INFINITY;

				//Sorting first by notification count then alphabetically
				if (aValue !== bValue) {
					return bValue - aValue;
				} else {
					// If values are equal, compare alphabetically
					return a.organization?.shortName
						?.replace('ТОО', '')
						.trim()
						.localeCompare(b.organization?.shortName?.replace('ТОО', '').trim());
				}
			});
			return [...data];
		}
		return [];
	}, [countersByWorkspaceId, data]);

	const filteredWorkspaces = useMemo(() => sortedWorkspaces.filter((v) => v?.id !== activeWorkspace?.id), [
		activeWorkspace?.id,
		sortedWorkspaces,
	]);

	const handleWorkspaceMenuClose = () => {
		setWorkspaceAnchorEl(null);
	};

	const fetchWorkspaceToken = useCallback(
		(activeWorkspace: Workspace) => {
			return getWorkspaceToken(activeWorkspace?.id, profile?.id);
		},
		[profile.id]
	);

	const handleWorkspaceSelect = useCallback(
		async (workspace: Workspace) => {
			const tokenResponse = await fetchWorkspaceToken(workspace);
			setWorkspaceAnchorEl(null);
			setWorkspace(workspace);
			localStorage.setItem(WORKSPACE_TOKEN_STORAGE_KEY, tokenResponse?.token);
			localStorage.setItem(WORKSPACE_STORAGE_KEY, JSON.stringify(workspace));

			// TODO: remove this redirect when backend resolves on its end
			// When user is changing current workspace, while being in timesheet
			// he can still see this timesheet, cause it's params are set in the url
			// so this workaround will redirect user to timesheets main screen
			if (history.location.pathname.includes(TIMESHEET_REDIRECT_URL)) {
				history.push(TIMESHEET_REDIRECT_URL);
				window.location.reload();
			} else if (relocate.find((item) => history.location.pathname.includes(item))) {
				window.location.replace('/overview');
			} else {
				window.location.reload();
			}
		},
		[history, fetchWorkspaceToken]
	);

	const handleWorkspaceMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		window._paq?.push([
			'trackEvent',
			'Nav',
			MATOMO_KEYS.WORKSPACES,
			JSON.stringify({ holdingId: workspace?.holdingId, companyId: workspace?.id }),
		]);

		setWorkspaceAnchorEl(event.currentTarget);
	};

	return (
		<>
			<div onClick={handleWorkspaceMenuOpen}>
				<Box className={`${classes.workspaceWrapper} ${workspaceMenuOpen && 'opened'}`}>
					<p className={classes.workspaceLabel}>{t('general.my_workspaces')}</p>
					<Box className={`${classes.workspaceContainer} ${filteredWorkspaces?.length === 1 && 'no-margin'}`}>
						<Badge
							color='error'
							overlap='rectangular'
							variant='dot'
							classes={badgeStyles}
							invisible={!isMini || !counters?.total}
						>
							{activeWorkspace?.organization?.logo ? (
								<img
									src={activeWorkspace?.organization.logo}
									alt={activeWorkspace?.organization.shortName}
									// onError={() => setShowLogo(false)}
									className={classes.logoWorkspace}
								/>
							) : (
								<Avatar
									style={{
										backgroundColor: Object.values(avatarBackgroundColors)[
											pickNumberFromString(activeWorkspace?.organization.shortName)
										],
									}}
									className={ownClasses.root}
								>
									{takeShortName(activeWorkspace?.organization.shortName)}
								</Avatar>
							)}
						</Badge>
						{!isMini && (
							<Box ml='10px'>
								<Tooltip title={activeWorkspace?.organization.name}>
									<Typography className={classes.workspaceFullName}>{activeWorkspace?.organization.shortName}</Typography>
								</Tooltip>
							</Box>
						)}
					</Box>
					{filteredWorkspaces?.length || isMini ? (
						<div className={`${classes.workspaceCountersWrapper}`}>
							{!isMini && (
								<>
									<div className={classes.workspaceCounters}>
										{filteredWorkspaces?.slice(0, WORKSPACES_TO_SHOW).map((workspace) => (
											<Tooltip title={workspace?.organization.name}>
												<Badge
													color='error'
													overlap='rectangular'
													variant='dot'
													classes={badgeStyles}
													invisible={!countersByWorkspaceId[workspace.id]}
												>
													{workspace.organization.logo ? (
														<img
															src={workspace.organization.logo}
															alt={workspace.organization.name}
															// onError={() => setShowLogo(false)}
															className={classes.logoWorkspaceSmall}
															key={workspace.id}
														/>
													) : (
														<Avatar
															key={workspace.id}
															className={ownClasses.smallAvatar}
															style={{
																backgroundColor: Object.values(avatarBackgroundColors)[
																	pickNumberFromString(workspace.organization.shortName)
																],
															}}
														>
															{takeShortName(workspace.organization.shortName)}
														</Avatar>
													)}
												</Badge>
											</Tooltip>
										))}
										{filteredWorkspaces?.length > WORKSPACES_TO_SHOW && (
											<Badge
												color='error'
												overlap='rectangular'
												variant='dot'
												classes={badgeStyles}
												invisible={
													!filteredWorkspaces.slice(WORKSPACES_TO_SHOW).some((workspace) => countersByWorkspaceId[workspace?.id])
												}
											>
												<Avatar className={ownClasses.smallAvatar}>+{filteredWorkspaces.length - WORKSPACES_TO_SHOW}</Avatar>
											</Badge>
										)}
									</div>
									{!!counters?.total && (
										<div className={ownClasses.totalCounter}>{counters.total > 99 ? '+99' : counters.total}</div>
									)}
								</>
							)}

							<RightArrowIcon className={ownClasses.arrow} />
						</div>
					) : null}
				</Box>
			</div>

			<WorkspacesMenu
				isOpen={workspaceMenuOpen}
				anchorEl={workspaceAnchorEl}
				onSelect={handleWorkspaceSelect}
				onClose={handleWorkspaceMenuClose}
				activeWorkspace={activeWorkspace}
				workspaces={sortedWorkspaces}
				isMini={isMini}
				countersByWorkspaceId={countersByWorkspaceId}
				variant={variant}
			/>
		</>
	);
};

export { Header };
