import * as React from 'react';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgOrgStructure({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d='M0 4a4 4 0 014-4h12a4 4 0 014 4v12a4 4 0 01-4 4H4a4 4 0 01-4-4V4z' fill='#0E3453' />
			<path
				d='M13.333 12.667L10.667 10V7.88c.9-.327 1.506-1.26 1.286-2.307a2.009 2.009 0 00-1.613-1.546A2 2 0 008 6c0 .867.56 1.6 1.333 1.88V10l-2.666 2.667h-2c-.367 0-.667.3-.667.666v2c0 .367.3.667.667.667h2c.366 0 .666-.3.666-.667v-1.366l2.667-2.8 2.667 2.8v1.366c0 .367.3.667.666.667h2c.367 0 .667-.3.667-.667v-2c0-.366-.3-.666-.667-.666h-2z'
				fill='#fff'
			/>
		</svg>
	);
}

const MemoSvgOrgStructure = React.memo(SvgOrgStructure);
export default MemoSvgOrgStructure;
