import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import chatBotLogo from '@topbar/src/assets/darvis-logo.png';

import { TypographyVariant } from '../sidebar-theme';
import { StyledChatBotSection } from './chat-bot-section-styles';
import { ChatBotSectionProps } from './chat-bot-section-types';
import { useChatBotSectionAnimations } from './hooks/use-chat-bot-section-animations';

const CHAT_BOT_SECTION_CONTAINER_ID = 'chat-bot-section-container-id';
const CHAT_BOT_TEXT_SECTION_ID = 'chat-bot-text-section-id';

export function ChatBotSection({ collapsed, onChatBotClick }: ChatBotSectionProps): ReactElement {
	const { t } = useTranslation();

	useChatBotSectionAnimations({
		chatBotSectionContainerId: CHAT_BOT_SECTION_CONTAINER_ID,
		chatBotSectionTextContainerId: CHAT_BOT_TEXT_SECTION_ID,
		collapsed,
	});

	return (
		<StyledChatBotSection.Container id={CHAT_BOT_SECTION_CONTAINER_ID} onClick={onChatBotClick} className='with-border'>
			<img src={chatBotLogo} alt='' width={32} style={{ minWidth: '32px' }} />
			<StyledChatBotSection.TextContainer id={CHAT_BOT_TEXT_SECTION_ID}>
				<StyledChatBotSection.Title>{t('chat_bot.title')}</StyledChatBotSection.Title>
				<StyledChatBotSection.SubTitle customVariant={TypographyVariant.SMALL}>
					{t('chat_bot.subtitle')}
				</StyledChatBotSection.SubTitle>
			</StyledChatBotSection.TextContainer>
		</StyledChatBotSection.Container>
	);
}
