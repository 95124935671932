import * as React from 'react';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgTimesheet({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d='M0 4a4 4 0 014-4h12a4 4 0 014 4v12a4 4 0 01-4 4H4a4 4 0 01-4-4V4z' fill='#FFA530' />
			<path
				d='M15.333 4.567H4.667c-.789 0-1.434.645-1.434 1.433v8c0 .789.645 1.433 1.434 1.433h10.666c.789 0 1.434-.644 1.434-1.433V6c0-.788-.645-1.433-1.434-1.433zm-8.1 4.666H4.767V6.1h2.466v3.133zm4 0H8.767V6.1h2.466v3.133zm4 0h-2.466V6.1h2.466v3.133zm-8 4.667H4.767v-3.133h2.466V13.9zm4 0H8.767v-3.133h2.466V13.9zm4 0h-2.466v-3.133h2.466V13.9z'
				fill='#fff'
				stroke='#fff'
				strokeWidth={0.2}
			/>
		</svg>
	);
}

const MemoSvgTimesheet = React.memo(SvgTimesheet);
export default MemoSvgTimesheet;
