import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgRequest({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <rect width={20} height={20} rx={4} fill="#1B74B4" />
      <path
        d="M14.94 6.94l-3.22-3.22a1.332 1.332 0 00-.94-.387H6c-.733 0-1.327.6-1.327 1.334l-.006 10.666c0 .734.593 1.334 1.326 1.334H14c.733 0 1.333-.6 1.333-1.334V7.887c0-.354-.14-.694-.393-.947zM11.867 12h-1.2v2c0 .367-.3.667-.667.667A.669.669 0 019.333 14v-2H8.14a.33.33 0 01-.233-.567l1.866-1.86a.342.342 0 01.474 0l1.86 1.86c.2.207.053.567-.24.567zm-.534-4a.669.669 0 01-.666-.667v-3L14.333 8h-3z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoSvgRequest = React.memo(SvgRequest);
export default MemoSvgRequest;
