import { useTranslation } from 'react-i18next';
import { isVideoConferenceUrl } from './isVideoConferenceUrl';

export enum TYPE_ENUM {
	LOG_OUT = 'meetings.leave_meeting_alert3',
	WORKSPACE = 'meetings.leave_meeting_alert2',
	TAB = 'meetings.leave_meeting_alert1',
}

export const useConfirm = () => {
	const { t } = useTranslation();

	const onConfirm = (callback, TYPE: TYPE_ENUM = TYPE_ENUM.TAB) => {
		if (!isVideoConferenceUrl(window.location)) {
			return callback(false);
		}
		// eslint-disable-next-line no-restricted-globals
		if (confirm(t(TYPE))) {
			callback(true);
		}
	};

	return { onConfirm };
};
