import React, { FC, useMemo, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { useTranslation } from 'react-i18next';

import { SideMenu } from '@dartech/dms-ui';
import { Profile as IProfile } from '@dartech/griffon-auth';
import { Box, Divider, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as ArrIcon } from '@topbar/src/assets/arr.svg';
import { ReactComponent as LogoDarleanPrimary } from '@topbar/src/assets/logo-darlean-primary.svg';
import { useAuth } from '@topbar/src/topbar/providers/auth-provider';
import { useBrand } from '@topbar/src/topbar/providers/brand-provider';

import { HEADER_LOGO_ID } from '../sidebar/sidebar';
import { SidebarHeader } from '../sidebar/sidebar-header/sidebar-header';
import { ReactComponent as Logo } from '../sidebar/svg/darlean-logo.svg';
import { Back, Content, Header, Profile, Settings } from './internal';
import { IRoute } from './nav-interface';
import useStyles from './style';

interface Props {
	backPath?: string;
	backText?: string;
	routes: (profile: IProfile, t: (v: string) => string) => IRoute[];
	variant: 'light' | 'dark';
	tab: 'plans' | 'projects';
}

const Nav: FC<Props> = ({ routes, variant, backPath, backText, tab }) => {
	const { profile, workspace } = useAuth();
	const { isNavMini, setisNavMini } = useBrand();
	const [topSize, setTopSize] = useState(0);
	const classes = useStyles({ isMini: isNavMini, variant });
	const { t } = useTranslation();

	const onMinimize = () => {
		setisNavMini((prev) => !prev);
	};

	return (
		<Div100vh className={`sticky ${classes.container} ${isNavMini ? 'mini' : ''} `}>
			<SidebarHeader link={{ to: '/overview', title: t('logo-link-title') }}>
				<Logo title={t('logo-title')} role='img' id={HEADER_LOGO_ID} />
			</SidebarHeader>
			<SideMenu isMini={isNavMini} variant={variant}>
				<Header isMini={isNavMini} workspace={workspace} variant={variant} />
				<Divider className={classes.divider} />
				{backPath && (
					<>
						<Back isMini={isNavMini} variant={variant} backPath={backPath} backText={backText} />
						{!isNavMini && (
							<Typography className={classes.settingsTitle}>
								{tab === 'projects' ? t('modules.project_settings') : t('modules.plan_settings')}
							</Typography>
						)}
						<Divider className={classes.divider} />
					</>
				)}
				<Content isMini={isNavMini} profile={profile} routes={routes} variant={variant} />
				<Divider className={classes.divider} />
				<Profile isMini={isNavMini} profile={profile} workspace={workspace} variant={variant} />
				{variant === 'dark' && (
					<>
						<Divider className={classes.divider} />
						<Settings isMini={isNavMini} variant={variant} />
						<Divider className={classes.divider} />
						{/* <ViewButton isMini={isNavMini} variant={variant} /> */}
					</>
				)}
			</SideMenu>
		</Div100vh>
	);
};

export { Nav };
