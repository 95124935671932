import _isEqual from 'lodash/isEqual';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Workspace } from '@dar/api-interfaces';
import {
	Avatar,
	Badge,
	Box,
	Grid,
	IconButton,
	InputAdornment,
	InputBase,
	Typography,
	createStyles,
	makeStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { useBadgeStyles } from '@topbar/src/components/nav/internal/header';
import { takeShortName } from '@topbar/src/components/nav/utils';
import { getRoleLocalizationKey } from '@topbar/src/utils/locale';

interface Props {
	open: boolean;
	activeCompany: Workspace;
	options: Workspace[];
	onSelect: (val: Workspace) => void;
	countersByWorkspaceId?: Record<string, number>;
}

const useStyles = makeStyles((theme) =>
	createStyles({
		inputRoot: {
			minWidth: 180,
			padding: '8px 12px',
			border: 'none',
			background: '#F7F8FC',
			'&:hover': { border: 'none' },
			'&:focus': { border: 'none', borderBottom: '1px solid red' },
		},
		inputBase: {
			width: '100%',
			borderRadius: 10,
			border: '2px solid #007994',
			background: 'white',
			'& input': {
				padding: '10px 12px',
				transition: theme.transitions.create(['border-color', 'box-shadow']),
				fontSize: 14,
				'&:active': {
					borderColor: 'red',
				},
				'&:focus': {
					borderColor: 'green',
				},
			},
		},
		inputAdorement: {
			margin: 0,
		},
		option: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			flex: 'auto',
		},
		search: {
			width: '100%',
			marginRight: '20px',
			'& path': {
				fill: '#6D7C8B',
			},
		},
		logo: {
			width: 40,
			height: 40,
			borderRadius: 6,
		},
		logoContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	})
);

const useOwnStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#497CF6',
		width: '40px',
		height: '40px',
		fontSize: '14px',
		border: '1px solid rgba(255, 255, 255, 0.6)',
		borderRadius: '6px',
	},
}));

const autoCompleteStyles = makeStyles((theme) => ({
	option: {
		margin: '0px 4px',
		borderRadius: '10px',
		'&:hover': {
			background: 'rgba(38, 40, 66, 0.04)',
		},
	},
	listbox: {
		maxHeight: '560px',
	},
}));

export const MenuAutoCompleteSelect = memo(
	({ open, activeCompany, options, onSelect, countersByWorkspaceId = {} }: Props) => {
		const { t } = useTranslation();
		const { t: hcmsT } = useTranslation('hcms');
		const badgeStyles = useBadgeStyles();
		const [showLogo, setShowLogo] = useState(true);
		const [inputValue, setInputValue] = useState('');
		const classes = useStyles();
		const ownClasses = useOwnStyles();
		const autoCompleteClasses = autoCompleteStyles();
		const onInputChange = (__: unknown, val: string) => {
			setInputValue(val);
		};
		const getOptionSelected = (option: Workspace, value: Workspace) => activeCompany && option['id'] === value['id'];
		const onChange = (event: React.ChangeEvent<unknown>, val: Workspace) => {
			onSelect(val);
		};

		const getTitle = (option: Workspace) => {
			const shortName = option?.organization?.shortName;
			const name = option?.organization?.name;
			if (shortName) {
				if (shortName === 'ТОО') return 'ТОО';
				return shortName?.replace('ТОО', '');
			}
			if (name) {
				if (name === 'ТОО') return 'ТОО';
				return name?.replace('ТОО', '');
			}
			return '';
		};

		const Pop = (props) => {
			const { className, anchorEl, style, ...rest } = props;
			return (
				<div
					{...rest}
					style={{
						zIndex: 9999,
					}}
				/>
			);
		};
		const Paper = (props) => {
			const { className, anchorEl, style, ...rest } = props;
			return (
				<div
					{...rest}
					style={{
						zIndex: 9999,
					}}
				/>
			);
		};
		return (
			<Autocomplete
				fullWidth
				open={open}
				getOptionSelected={getOptionSelected}
				getOptionLabel={(option) => option?.organization?.shortName || ''}
				options={options}
				inputValue={inputValue}
				classes={autoCompleteClasses}
				onChange={onChange}
				onInputChange={onInputChange}
				noOptionsText={t('general.nothingFound')}
				PaperComponent={Paper}
				className={classes.inputRoot}
				renderInput={(params) => (
					<InputBase
						autoFocus
						onKeyDown={(event) => event.stopPropagation()}
						value={inputValue}
						placeholder={t('general.search')}
						ref={params.InputProps.ref}
						inputProps={{ ...params.inputProps }}
						className={classes.inputBase}
						endAdornment={
							<InputAdornment position='end' className={classes.inputAdorement}>
								{inputValue !== '' ? (
									<IconButton
										size='small'
										className={classes.search}
										onClick={() => {
											setInputValue('');
										}}
									>
										<ClearIcon />
									</IconButton>
								) : (
									<SearchIcon className={classes.search} />
								)}
							</InputAdornment>
						}
					/>
				)}
				renderOption={(option) => {
					return (
						<div
							ref={(el) => {
								if (option.id === activeCompany.id && el) {
									el.parentElement.style.background = 'rgba(38, 40, 66, 0.08)';
								}
							}}
							className={classes.option}
						>
							<Grid container alignItems='center' style={{ position: 'relative' }}>
								<Badge
									badgeContent={
										countersByWorkspaceId[option.id] &&
										(countersByWorkspaceId[option.id] > 99 ? '+99' : countersByWorkspaceId[option.id])
									}
									style={{
										position: 'absolute',
										top: 3,
										left: 5,
									}}
									classes={badgeStyles}
									color='error'
									overlap='rectangular'
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
								/>
								<Grid item className={classes.logoContainer}>
									{option?.organization?.logo && showLogo ? (
										<img
											src={option?.organization?.logo}
											alt={getTitle(option)}
											onError={() => setShowLogo(false)}
											className={classes.logo}
										/>
									) : (
										<Avatar classes={ownClasses}>{takeShortName(option?.organization?.name)}</Avatar>
									)}
								</Grid>
								<Grid item>
									<Box display='flex' flexDirection='column' marginLeft='8px'>
										<Typography
											style={{
												fontSize: '14px',
												lineHeight: '18px',
												fontWeight: 500,
											}}
										>
											{getTitle(option)}
										</Typography>
										<Typography
											style={{
												color: '#8B8C9E',
												fontSize: '14px',
												lineHeight: '18px',
												fontWeight: 400,
											}}
										>
											{`${t('login.wrkspc_list_member', {
												N: option?.numberOfEmployees,
											})}${option?.role ? ` · ${hcmsT(getRoleLocalizationKey(option.role))}` : ''}`}
										</Typography>
									</Box>
								</Grid>
							</Grid>
							{activeCompany.id === option.id && <CheckIcon style={{ color: '#6D7C8B', marginRight: '9px' }} />}
						</div>
					);
				}}
				PopperComponent={Pop}
			/>
		);
	},
	(prevProps, nextProps) =>
		prevProps.open === nextProps.open &&
		prevProps.activeCompany.id === nextProps.activeCompany.id &&
		_isEqual(prevProps.options, nextProps.options) &&
		_isEqual(prevProps.countersByWorkspaceId, nextProps.countersByWorkspaceId)
);
