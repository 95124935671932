import React, { ReactElement } from 'react';

import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { sidebarTheme } from '../../../sidebar-theme';

type TooltipProps = MuiTooltipProps & { isHidden?: boolean };

const useStyles = makeStyles<Record<never, never>, Pick<TooltipProps, 'isHidden'>>(() =>
	createStyles({
		tooltip: {
			padding: `${sidebarTheme.spacing['positive-100']} ${sidebarTheme.spacing['positive-150']}`,
			fontFamily: 'Euclid',
			fontSize: '14px',
			lineHeight: '18px',
			whiteSpace: 'pre-wrap',
			display: ({ isHidden }) => (isHidden ? 'none' : 'block'),
		},
	})
);

export function Tooltip({ children, isHidden, ...rest }: TooltipProps): ReactElement {
	const classes = useStyles({ isHidden });

	return (
		<MuiTooltip {...rest} classes={classes}>
			{children}
		</MuiTooltip>
	);
}
