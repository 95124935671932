import * as React from 'react';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgPerson({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<rect width={20} height={20} rx={4} fill='#1B74B4' />
			<path
				d='M10 10a2.666 2.666 0 100-5.333A2.666 2.666 0 1010 10zm0 1.333c-1.78 0-5.333.894-5.333 2.667v.667c0 .366.3.666.666.666h9.334c.366 0 .666-.3.666-.666V14c0-1.773-3.553-2.667-5.333-2.667z'
				fill='#fff'
			/>
		</svg>
	);
}

const MemoSvgPerson = React.memo(SvgPerson);
export default MemoSvgPerson;
