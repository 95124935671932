import * as React from 'react';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgTasks({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d='M0 4a4 4 0 014-4h12a4 4 0 014 4v12a4 4 0 01-4 4H4a4 4 0 01-4-4V4z' fill='#2DB77B' />
			<path
				d='M15.333 3.333h-8C6.6 3.333 6 3.933 6 4.667v8C6 13.4 6.6 14 7.333 14h8c.734 0 1.334-.6 1.334-1.333v-8c0-.734-.6-1.334-1.334-1.334zm-5.493 7.52L8.46 9.467a.668.668 0 010-.934c.26-.26.68-.26.94 0l.907.914 2.946-2.974c.26-.26.68-.26.94 0a.668.668 0 010 .934l-3.42 3.446a.645.645 0 01-.933 0zM4 6c-.367 0-.667.3-.667.667v8.666c0 .734.6 1.334 1.334 1.334h8.666c.367 0 .667-.3.667-.667 0-.367-.3-.667-.667-.667h-8a.669.669 0 01-.666-.666v-8C4.667 6.3 4.367 6 4 6z'
				fill='#fff'
			/>
		</svg>
	);
}

const MemoSvgTasks = React.memo(SvgTasks);
export default MemoSvgTasks;
