import React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { Workspace } from '@dar/api-interfaces';
import { UserAvatar } from '@dartech/dms-ui';
import { Profile as IProfile } from '@dartech/griffon-auth';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useCompanyID } from '@topbar/src/hooks/useCompanyID';
import { getEmployeeItem } from '@topbar/src/services/employee';
import { getRoleLocalizationKey } from '@topbar/src/utils/locale';

import useStyles from '../style';
import { ProfileMenu } from './profile-menu';

interface Props {
	profile: IProfile;
	isMini: boolean;
	workspace: Workspace;
	variant: 'light' | 'dark';
}

const Profile = ({ profile, isMini, workspace: activeWorkspace, variant }: Props) => {
	const classes = useStyles({ isMini, variant });
	const companyId = useCompanyID();
	const { t } = useTranslation('hcms');

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const { data, isLoading } = useQuery(
		['GET_EMPLOYEE_ITEM', profile.id],
		() => getEmployeeItem({ companyId, profileId: profile.id }),
		{
			enabled: Boolean(profile?.id) && !!activeWorkspace,
		}
	);
	return (
		<Box p='12px' className={`${classes.profileWrapper} ${open && 'opened'}`}>
			<Box className={classes.profileContainer} onClick={handleMenu}>
				{isLoading ? (
					<CircularProgress size={32} />
				) : (
					<UserAvatar
						size={32}
						fontSize={14}
						userName={[data?.name.first, data?.name.last || ' ']}
						src={data?.photo}
						className={classes.avatar}
					/>
				)}
				{!isMini && (
					<Box ml='10px'>
						<Typography className={classes.role}>{t(getRoleLocalizationKey(activeWorkspace?.role))}</Typography>
						<Typography className={classes.email} color='textSecondary'>
							{profile?.email}
						</Typography>
					</Box>
				)}
			</Box>
			<ProfileMenu
				anchorEl={anchorEl}
				onClose={handleClose}
				open={open}
				isMini={isMini}
				variant={variant}
				activeWorkspace={activeWorkspace}
				profile={profile}
				data={data}
			/>
		</Box>
	);
};

export { Profile };
