import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Workspace } from '@dar/api-interfaces';
import { notificationsService } from '@topbar/src/services/notifications';

import { TransformedCountersByWorkspaceId, transformCountersByWorkspaceId } from '../utils/transform-counters-by-workspace';

type UseCountersByHoldings = {
	holdings: Workspace['holdingId'][];
};

const GET_COUNTERS_QUERY_KEY = 'holdingsNotificationsCounters';

export function useTransformedCountersByWorkspaceId({ holdings }: UseCountersByHoldings): TransformedCountersByWorkspaceId {
	const { data: counters } = useQuery(
		GET_COUNTERS_QUERY_KEY,
		() => notificationsService?.getNotificationsCountersByHoldings(holdings),
		{
			refetchInterval: 10000,
			enabled: !!holdings?.length,
		}
	);

	const countersByWorkspaceId = useMemo(() => {
		if (!counters?.workspaces) {
			return {};
		}

		return transformCountersByWorkspaceId(counters.workspaces);
	}, [counters]);

	return countersByWorkspaceId;
}
