import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Box, CircularProgress } from '@material-ui/core';
import { MATOMO_KEYS } from '@topbar/src/constants/matomo';
import { useCompanyID, useWorkspace } from '@topbar/src/hooks/useCompanyID';
import { getEmployeeItem } from '@topbar/src/services/employee';
import { getRoleLocalizationKey } from '@topbar/src/utils/locale';

import { TypographyWithHint } from '../components/shared/typography-with-hint/typography-with-hint';
import { useAnchorEl } from '../hooks/use-anchor-el';
import { TypographyVariant } from '../sidebar-theme';
import { ReactComponent as ArrowRight } from '../svg/arrow-right.svg';
import { ProfileMenu } from './components/profile-menu/profile-menu';
import { useProfileAnimations } from './hooks/use-profile-animations';
import { StyledProfile } from './profile-styles';
import { ProfileProps } from './profile-types';

export const PROFILE_USER_INFO_ID = 'profile-user-info';

const Profile = ({ profile, workspace: activeWorkspace, collapsed }: ProfileProps) => {
	const { t } = useTranslation('hcms');
	const workspace = useWorkspace();
	const { anchorEl, isOpen, onModalClose, onModalOpen } = useAnchorEl();
	useProfileAnimations({
		profileUserInfoId: PROFILE_USER_INFO_ID,
		collapsed,
	});

	const onProfileClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			window._paq?.push([
				'trackEvent',
				'Nav',
				MATOMO_KEYS.PROFILE,
				JSON.stringify({ holdingId: workspace?.holdingId, companyId: workspace?.id }),
			]);
			onModalOpen(event);
		},
		[onModalOpen]
	);

	const { data, isLoading } = useQuery(
		['GET_EMPLOYEE_ITEM', profile.id],
		() => getEmployeeItem({ companyId: workspace.id, profileId: profile.id }),
		{
			enabled: Boolean(profile.id),
		}
	);

	return (
		<>
			<StyledProfile.ProfileWrapper $expanded={isOpen} onClick={onProfileClick}>
				<StyledProfile.ProfileContainer title={`${data?.name.first || ' '} ${data?.name.last || ' '}`}>
					{isLoading ? (
						<CircularProgress size={32} />
					) : (
						<StyledProfile.Avatar
							size={32}
							fontSize={14}
							userName={[data?.name.first, data?.name.last || ' ']}
							src={data?.photo}
						/>
					)}
					<Box ml='10px' minWidth={0} id={PROFILE_USER_INFO_ID}>
						<StyledProfile.Role>{t(getRoleLocalizationKey(activeWorkspace?.role))}</StyledProfile.Role>
						<TypographyWithHint
							text={profile?.email}
							shortWordMaxLength={22}
							render={(text) => <StyledProfile.Email customVariant={TypographyVariant.SMALL}>{text}</StyledProfile.Email>}
						/>
					</Box>
				</StyledProfile.ProfileContainer>
				<ArrowRight />
			</StyledProfile.ProfileWrapper>
			<ProfileMenu anchorEl={anchorEl} onClose={onModalClose} open={isOpen} profile={profile} />
		</>
	);
};

export { Profile };
