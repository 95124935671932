import { UserAvatar } from '@dartech/dms-ui';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';

import { Typography } from '../components/shared/typography/typography';
import { sidebarTheme } from '../sidebar-theme';

const ProfileWrapper = styled.div<{ $expanded: boolean }>`
	border-top: ${sidebarTheme.border.default};
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ${sidebarTheme.spacing['positive-150']};
	max-height: 58px;

	&:hover {
		background: #f7f7f8;
		cursor: pointer;
	}

	&:active {
		background: #eeeef0;
	}

	${(props) =>
		props.$expanded &&
		`
		background: #eeeef0;
	`}

	&:focus-visible {
		background: #f7f7f8;
		border: 2px solid #039be6;
		padding: calc(${sidebarTheme.spacing['positive-150']} - 1px) ${sidebarTheme.spacing['positive-125']};
		outline: none;
	}
`;

const ProfileContainer = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
`;

const Avatar = styled(UserAvatar)`
	width: 32px;
	height: 32px;
	margin: 0px !important;
`;

const Role = styled(Typography)`
	color: #262842;
`;

const Email = styled(Typography)`
	color: #6d6e85;
`;

export const StyledProfile = {
	ProfileWrapper,
	ProfileContainer,
	Avatar,
	Role,
	Email,
};
