import * as React from 'react';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgMom({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d='M0 4a4 4 0 014-4h12a4 4 0 014 4v12a4 4 0 01-4 4H4a4 4 0 01-4-4V4z' fill='#976AD1' />
			<path
				d='M14.667 4H11.88A2.007 2.007 0 0010 2.667c-.867 0-1.6.56-1.88 1.333H5.333C4.6 4 4 4.6 4 5.333v9.334C4 15.4 4.6 16 5.333 16h9.334C15.4 16 16 15.4 16 14.667V5.333C16 4.6 15.4 4 14.667 4zM10 4c.367 0 .667.3.667.667 0 .366-.3.666-.667.666a.669.669 0 01-.667-.666c0-.367.3-.667.667-.667zm.667 9.333H7.333a.669.669 0 01-.666-.666c0-.367.3-.667.666-.667h3.334c.366 0 .666.3.666.667 0 .366-.3.666-.666.666zm2-2.666H7.333A.669.669 0 016.667 10c0-.367.3-.667.666-.667h5.334c.366 0 .666.3.666.667 0 .367-.3.667-.666.667zm0-2.667H7.333a.669.669 0 01-.666-.667c0-.366.3-.666.666-.666h5.334c.366 0 .666.3.666.666 0 .367-.3.667-.666.667z'
				fill='#fff'
			/>
		</svg>
	);
}

const MemoSvgMom = React.memo(SvgMom);
export default MemoSvgMom;
