import { lazy } from 'react';

import { BudgetSettingsNav } from '../../components/budget-nav/budget-settings-nav';
import { PlanSettingsNav } from '../../components/project-nav/plan-settings-nav';
import { ProjectSettingsNav } from '../../components/project-nav/project-settings-nav';

const Topbar = lazy(() => import('../topbar').then((v) => ({ default: v.Topbar })));
const SecurityPage = lazy(() => import('../../pages/user-settings/security-page').then((v) => ({ default: v.SecurityPage })));
const JoinMeeting = lazy(() => import('../../pages/join-meeting/join-meeting').then((v) => ({ default: v.JoinMeeting })));
const Congrats = lazy(() => import('../../pages/congrats').then((v) => ({ default: v.Congrats })));
const Signin = lazy(() => import('../../pages/signin/signin-page').then((v) => ({ default: v.SignInPage })));
const Signup = lazy(() => import('../../pages/signup/signup-page').then((v) => ({ default: v.SignupPage })));
const SignupCode = lazy(() => import('../../pages/signup-code/signup-code-page').then((v) => ({ default: v.SignupCodePage })));
const SignUpSocialCode = lazy(() =>
	import('../../pages/signup-code/signup-social-code-page').then((v) => ({ default: v.SignupSocialCodePage }))
);
const CreatePassword = lazy(() =>
	import('../../pages/create-password/create-password').then((v) => ({ default: v.CreatePasswordPage }))
);
const ResetPassword = lazy(() => import('../../pages/reset-password/reset-password').then((v) => ({ default: v.ResetPassword })));
const ResetPasswordCode = lazy(() =>
	import('../../pages/reset-password-code/reset-password-code').then((v) => ({ default: v.ResetPasswordCode }))
);
const InvitationOnboarding = lazy(() =>
	import('../../pages/invitation-onboarding/invitation-onboarding').then((v) => ({ default: v.InvitationOnboarding }))
);
const UpdatePassword = lazy(() =>
	import('../../pages/update-password/update-password').then((v) => ({ default: v.UpdatePassword }))
);
const LoginFromLanding = lazy(() =>
	import('../../pages/login-from-landing/login-from-landing').then((v) => ({ default: v.LoginFromLandingPage }))
);
const LoginFromLandingSocial = lazy(() =>
	import('../../pages/login-from-landing/login-from-landing-social').then((v) => ({ default: v.LoginFromLandingSocial }))
);
const LoginSocial = lazy(() => import('../../pages/login-social/login-social').then((v) => ({ default: v.LoginSocial })));
const LoginSocialMobile = lazy(() =>
	import('../../pages/login-social/login-social-mobile').then((v) => ({ default: v.LoginSocialMobile }))
);
const CreateWorkspacePage = lazy(() =>
	import('../../pages/create-workspace/create-workspace-page').then((v) => ({ default: v.CreateWorkspacePage }))
);
const Nav = lazy(() => import('../../components/nav/menu-nav').then((v) => ({ default: v.MenuNav })));
const SettingsNav = lazy(() => import('../../components/nav/settings-nav').then((v) => ({ default: v.SettingsNav })));
const CnbSettingsNav = lazy(() => import('../../components/nav/cnb-settings-nav').then((v) => ({ default: v.CnbSettingsNav })));
// const ProjectSettingsNav = lazy(() => import('../../components/projectNav/projectSettings.na({default: v.component})').then(v => ({default: v.Topbar})));
const ProcessesSettingsNav = lazy(() =>
	import('../../components/nav/processes-settings-nav').then((v) => ({
		default: v.ProcessesSettingsNav,
	}))
);
const Docflow = lazy(() => import('../../pages/docflow/docflow').then((v) => ({ default: v.Docflow })));

export default {
	Topbar,
	Nav,
	SecurityPage,
	JoinMeeting,
	Congrats,
	Signin,
	Signup,
	SignupCode,
	SignUpSocialCode,
	CreatePassword,
	ResetPassword,
	ResetPasswordCode,
	InvitationOnboarding,
	UpdatePassword,
	CreateWorkspacePage,
	SettingsNav,
	ProjectSettingsNav,
	PlanSettingsNav,
	ProcessesSettingsNav,
	LoginFromLanding,
	LoginFromLandingSocial,
	LoginSocial,
	LoginSocialMobile,
	BudgetSettingsNav,
	CnbSettingsNav,
	Docflow,
};
