import React, { Suspense, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Route, useHistory, useLocation } from 'react-router-dom';

import { BillingService } from '@topbar/src/billing/billing-service';
import { CookieDrawer } from '@topbar/src/components/cookie-drawer/cookie-drawer-wrapper';
import { useCookieScripts } from '@topbar/src/components/cookie-scripts';
import { Spinner } from '@topbar/src/components/spinner-loader';
import { domainInfo } from '@topbar/src/utils/domain';
import { isAndroid, isIos, isOpera, isWindowsMobile } from '@topbar/src/utils/platform';

import { NotificationService } from '../../notifications/notification-service';
import { useAuth } from '../providers/auth-provider';
import routes from './app-lazy';
import { Onbording } from './app-onbording';
import { Settings, registrationRoutes } from './app-settings';

export const notificationService = new NotificationService();
export const billingService = new BillingService();

const App = () => {
	const { loadScripts } = useCookieScripts();

	const history = useHistory();
	const { authenticated, invited, workspace, workspaceProcessing } = useAuth();
	const { pathname } = useLocation();
	const paths = pathname.split('/').slice(1);
	const isCreateWorkspacePages = paths[0].includes('workspace');
	const isAdmin =
		paths[0].includes('admin') || pathname.includes('/people/settings') || pathname.includes('/processes/settings/roles');

	useEffect(() => {
		authenticated && loadScripts();
	}, [authenticated, loadScripts]);

	useEffect(() => {
		if (authenticated && !isCreateWorkspacePages && (isIos() || isOpera() || isWindowsMobile() || isAndroid())) {
			history.push('/create-workspace/mobileview');
		}
	}, [history, authenticated, isCreateWorkspacePages]);

	useEffect(() => {
		if (registrationRoutes.includes(pathname) || pathname.includes('/invitation/')) {
			localStorage.setItem('pageUrl', '"/overview"');
		} else {
			localStorage.setItem('pageUrl', JSON.stringify(pathname));
		}
	}, [pathname]);

	const qc = useQueryClient();
	//Listen to event when profile data updates.
	//Like userName or user avatar
	useEffect(() => {
		const listener = () => {
			qc.invalidateQueries(['GET_EMPLOYEE_ITEM']);
		};

		window.addEventListener('PROFILE_UPDATE', listener);

		return () => {
			window.removeEventListener('PROFILE_UPDATE', listener);
		};
	}, [qc]);

	if (workspaceProcessing) return <Spinner fullscreen />;
	if (authenticated && !invited && (isCreateWorkspacePages || !workspace) && !workspaceProcessing) {
		return (
			<Suspense fallback>
				<routes.CreateWorkspacePage />
			</Suspense>
		);
	}

	//Proceed further to reauthenticate with new token from landing
	if (authenticated && !invited && !isCreateWorkspacePages && !pathname.includes('from-landing')) {
		if (isAdmin) {
			return (
				<Suspense fallback>
					<routes.SettingsNav />
				</Suspense>
			);
		}
		if (paths.includes('docflow-sign')) {
			return (
				<Suspense fallback>
					<Route path='/docflow-sign' component={routes.Docflow} />
				</Suspense>
			);
		}
		if (paths[0].includes('congratulations')) {
			return (
				<Suspense fallback>
					<Route path='/congratulations' component={routes.Congrats} />
				</Suspense>
			);
		}
		if (paths.includes('projects') && paths.includes('settings')) {
			return (
				<Suspense fallback>
					<routes.ProjectSettingsNav />
				</Suspense>
			);
		}
		if (paths.includes('plans') && paths.includes('settings')) {
			return (
				<Suspense fallback>
					<routes.PlanSettingsNav />
				</Suspense>
			);
		}
		if (paths.includes('budget') && paths.includes('settings')) {
			return (
				<Suspense fallback>
					<routes.BudgetSettingsNav />
				</Suspense>
			);
		}
		if (paths.includes('processes') && paths.includes('settings') && paths.includes('groups')) {
			return (
				<Suspense fallback>
					<routes.ProcessesSettingsNav />
				</Suspense>
			);
		}
		if (paths.includes('cnb-settings')) {
			return (
				<Suspense fallback>
					<routes.CnbSettingsNav />
				</Suspense>
			);
		}
		return (
			<>
				<Suspense fallback>
					<routes.Nav />
				</Suspense>
				<Suspense fallback>
					<Settings />
				</Suspense>
			</>
		);
	}

	return (
		<>
			<Onbording authenticated={authenticated} invited={invited} />
			{/* <CookieDrawer /> */}
		</>
	);
};

export { App };
