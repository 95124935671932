import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { SideMenuCollapse, SideMenuContent, SideMenuListItem, SideMenuModal } from '@dartech/dms-ui';
import { Profile } from '@dartech/griffon-auth';
import { Profile as IProfile } from '@dartech/griffon-auth';
import { Box } from '@material-ui/core';
import { ReactComponent as DangerIcon } from '@topbar/src/assets/danger_icon.svg';
import { getProject } from '@topbar/src/services/projects';

import { IRoute } from '../nav-interface';
import useStyles from '../style';
import { projectSettingsBehaviorSubject$ } from './behavior-subject';

interface Props {
	profile: Profile;
	isMini: boolean;
	routes: (profile: IProfile, t: (v: string) => string) => IRoute[];
	variant: 'light' | 'dark';
}

const Content = ({ profile, isMini, routes, variant }: Props) => {
	const { t } = useTranslation();
	const classes = useStyles({ isMini, variant });
	const history = useHistory();
	const { pathname } = useLocation();

	const { data, refetch } = useQuery({
		queryKey: 'project',
		queryFn: () => getProject(pathname.split('/')[3]),
	});

	const onClick = (e, route) => {
		if (route?.length) {
			history.push(route);
		}
	};

	const isDeactivatedUsersInTeam = useMemo(() => {
		if (data) {
			return data.team.some((item) => item.deactivate);
		}
		return false;
	}, [data]);

	const isDeactivatedManager = useMemo(() => {
		if (data) {
			return data.team.find((item) => item.userId === data.projectManager)?.deactivate;
		}
		return false;
	}, [data]);

	useEffect(() => {
		if (projectSettingsBehaviorSubject$) {
			const subscription = projectSettingsBehaviorSubject$.subscribe((value) => {
				if (value) {
					refetch();
				}
			});

			return () => subscription.unsubscribe();
		}
	}, []);

	return (
		<SideMenuContent>
			{routes(profile, t).map((item) => {
				if (item.itemId) {
					return (
						<Box className={classes.listItemWrapper}>
							<SideMenuListItem
								title={item.title}
								itemId={item.itemId}
								icon={<item.icon className={classes.menuIcon} />}
								customClickHandler={onClick}
								route={item.route}
								filledIcon={<item.filledIcon className={classes.menuIcon} />}
							/>
							{item.itemId === 'projectDetails' && isDeactivatedManager && <DangerIcon className={classes.endIcon} />}
							{item.itemId === 'projectTeam' && isDeactivatedUsersInTeam && <DangerIcon className={classes.endIcon} />}
						</Box>
					);
				}
				if (item.forCollapse) {
					return (
						<>
							<SideMenuListItem
								forCollapse={item.forCollapse}
								title={item.title}
								icon={<item.icon className={classes.menuIcon} />}
								customClickHandler={onClick}
								filledIcon={<item.filledIcon className={classes.menuIcon} />}
								collapseRoute={item.collapseRoute}
							/>
							<SideMenuCollapse collapseId={item.forCollapse}>
								{item.children.map((subItem) => (
									<SideMenuListItem
										title={subItem.title}
										itemId={subItem.itemId}
										customClickHandler={onClick}
										route={subItem.route}
										parentTitle={item.title}
									/>
								))}
							</SideMenuCollapse>
							<SideMenuModal
								collapseId={item.forCollapse}
								headerTitle={item.title}
								icon={<item.icon className={classes.menuIcon} />}
								filledIcon={<item.filledIcon className={classes.menuIcon} />}
							>
								{item.children.map((subItem) => (
									<SideMenuListItem
										title={subItem.title}
										itemId={subItem.itemId}
										isMenu
										customClickHandler={onClick}
										route={subItem.route}
										parentTitle={item.title}
									/>
								))}
							</SideMenuModal>
						</>
					);
				}
			})}
		</SideMenuContent>
	);
};

export { Content };
