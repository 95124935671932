import * as React from 'react';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgCalendarLtrRegular({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				d='M14.792 2.5A2.708 2.708 0 0117.5 5.208v9.584a2.708 2.708 0 01-2.708 2.708H5.208A2.708 2.708 0 012.5 14.792V5.208A2.708 2.708 0 015.208 2.5h9.584zm1.458 4.583H3.75v7.709c0 .805.653 1.458 1.458 1.458h9.584a1.458 1.458 0 001.458-1.458V7.083zm-9.792 5a1.042 1.042 0 110 2.084 1.042 1.042 0 010-2.084zm3.542 0a1.042 1.042 0 110 2.084 1.042 1.042 0 010-2.084zM6.458 8.75a1.042 1.042 0 110 2.083 1.042 1.042 0 010-2.083zm3.542 0a1.042 1.042 0 110 2.084 1.042 1.042 0 010-2.084zm3.542 0a1.042 1.042 0 110 2.083 1.042 1.042 0 010-2.083zm1.25-5H5.208A1.458 1.458 0 003.75 5.208v.625h12.5v-.625a1.458 1.458 0 00-1.458-1.458z'
				fill='#262842'
			/>
		</svg>
	);
}

const MemoSvgCalendarLtrRegular = React.memo(SvgCalendarLtrRegular);
export default MemoSvgCalendarLtrRegular;
