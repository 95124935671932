import * as React from 'react';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgBookSearchRegular({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				d='M12.35 9.83a3.104 3.104 0 10-.854.852l2.063 2.063.056.048.007.005a.603.603 0 00.785-.91l-2.059-2.059h.001zM9.77 6.205a1.897 1.897 0 110 3.794 1.897 1.897 0 010-3.794zm-4.353-4.54A2.083 2.083 0 003.333 3.75v12.5a2.083 2.083 0 002.084 2.083h11.041a.625.625 0 100-1.25H5.417a.834.834 0 01-.834-.833h11.875a.625.625 0 00.625-.625V3.75A2.083 2.083 0 0015 1.667H5.417zM15.833 15H4.583V3.75a.833.833 0 01.834-.833H15a.833.833 0 01.833.833V15z'
				fill='#262842'
			/>
		</svg>
	);
}

const MemoSvgBookSearchRegular = React.memo(SvgBookSearchRegular);
export default MemoSvgBookSearchRegular;
