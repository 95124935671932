import { domainInfo } from '../utils/domain';

export const gtmScript = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-MMC84VP');
`;

export const matomoScript = `
  var profile = JSON.parse(localStorage.getItem('profile') || 'null');
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  _paq.push(['setUserId', profile.id]);
  (function() {
    var u="https://5qit.matomo.cloud/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '1']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/5qit.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
  })();
`;

const euBitrixLink = 'https://cdn.bitrix24.eu/b25053179/crm/site_button/loader_4_vq8w6m.js';
const idBitrixLink = 'https://cdn-ru.bitrix24.ru/b21828152/crm/site_button/loader_28_kz7gk8.js';
const kzBitrixLink = 'https://cdn-ru.bitrix24.ru/b21828152/crm/site_button/loader_8_mg8zve.js';

const getBitrixLink = () => {
	if (domainInfo.isCOM()) return euBitrixLink;
	if (domainInfo.isKZ()) return kzBitrixLink;
	if (domainInfo.isDarIo()) return kzBitrixLink;
	if (domainInfo.isID()) return idBitrixLink;

	return idBitrixLink;
};

export const getBitrixScript = () => `
  (function(w,d,u){
    var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);
    var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
  })(window,document,'${getBitrixLink()}');
`;
