import { TFunction } from 'i18next';

import { CommunityItem, NotificationCounters, Workspace } from '@dar/api-interfaces';
import { ReactComponent as Budget } from '@topbar/src/components/sidebar/svg/budget.svg';
import { ReactComponent as Calendar } from '@topbar/src/components/sidebar/svg/calendar.svg';
import { ReactComponent as Cnb } from '@topbar/src/components/sidebar/svg/cnb.svg';
import { ReactComponent as Company } from '@topbar/src/components/sidebar/svg/company.svg';
import { ReactComponent as Dashobards } from '@topbar/src/components/sidebar/svg/dashboards.svg';
import { ReactComponent as DocflowCounterparties } from '@topbar/src/components/sidebar/svg/docflow-counterparties.svg';
import { ReactComponent as DocflowLegalForms } from '@topbar/src/components/sidebar/svg/docflow-legal-forms.svg';
import { ReactComponent as DocflowTemplates } from '@topbar/src/components/sidebar/svg/docflow-templates.svg';
import { ReactComponent as Docflow } from '@topbar/src/components/sidebar/svg/docflow.svg';
import { ReactComponent as Drive } from '@topbar/src/components/sidebar/svg/drive.svg';
import { ReactComponent as Holding } from '@topbar/src/components/sidebar/svg/holding.svg';
import { ReactComponent as Home } from '@topbar/src/components/sidebar/svg/home.svg';
import { ReactComponent as LMS } from '@topbar/src/components/sidebar/svg/lms.svg';
import { ReactComponent as Marketplace } from '@topbar/src/components/sidebar/svg/marketplace.svg';
import { ReactComponent as MeetingMinutes } from '@topbar/src/components/sidebar/svg/meeting-minutes.svg';
import { ReactComponent as Plans } from '@topbar/src/components/sidebar/svg/plans.svg';
import { ReactComponent as Projects } from '@topbar/src/components/sidebar/svg/projects.svg';
import { ReactComponent as Requests } from '@topbar/src/components/sidebar/svg/requests.svg';
import { ReactComponent as Tasks } from '@topbar/src/components/sidebar/svg/tasks.svg';
import { ReactComponent as Timesheets } from '@topbar/src/components/sidebar/svg/timesheets.svg';
import { MATOMO_KEYS } from '@topbar/src/constants/matomo';
import { PERMISSIONS_ACTIONS, PERMISSIONS_TYPES } from '@topbar/src/constants/permissions';
import { environment } from '@topbar/src/environments/environment';
import { domainInfo } from '@topbar/src/utils/domain';
import { hasPermission } from '@topbar/src/utils/workspace';

export enum ROUTES {
	HOME = 'HOME',
	CALENDAR = 'CALENDAR',
	TASKS = 'TASKS',
	REQUESTS = 'REQUESTS',
	BUDGET = 'BUDGET',
	CNB = 'CNB',
	DRIVE = 'DRIVE',
	COMPANY = 'COMPANY',
	HOLDING = 'HOLDING',
	PROJECTS = 'PROJECTS',
	PLANS = 'PLANS',
	DASHBOARD = 'DASHBOARD',
	DOC_FLOW = 'DOC_FLOW',
	DOC_FLOW_TEMPLATES = 'DOC_FLOW_TEMPLATES',
	DOC_FLOW_COUNTERPARTIES = 'DOC_FLOW_COUNTERPARTIES',
	DOC_FLOW_LEGAL_FORMS = 'DOC_FLOW_LEGAL_FORMS',
	MEETING_MINUTES = 'MEETING_MINUTES',
	TIMESHEETS = 'TIMESHEETS',
	MORE = 'MORE',
	COMMUNITY = 'COMMUNITY',
	LMS = 'LMS',
}

export type MenuItem = {
	id: ROUTES;
	title: string;
	icon: React.FunctionComponent<
		React.SVGProps<SVGSVGElement> & {
			title?: string;
		}
	>;
	route: string;
	matomoKey?: MATOMO_KEYS;
	isBeta?: boolean;
	notificationCounter: number;
	hasPermission?: () => boolean;
};

type Permission = {
	type: PERMISSIONS_TYPES;
	actions: readonly PERMISSIONS_ACTIONS[];
};

const hasPermissionToView = (permissions: readonly Permission[] = []) => {
	if (permissions.length <= 0) return true;

	return permissions?.reduce((acc, permission) => {
		permission.actions.forEach((action) => {
			if (hasPermission(permission.type, action)) {
				acc = true;
			}
		});

		return acc;
	}, false);
};

export function getWorkspaceInfo(): Workspace | null {
	return JSON.parse(localStorage.getItem('workspace') || null);
}

const isDev = environment().envName === 'development' || environment().envName === 'local';
const isProdEuID = domainInfo.isCOM() || domainInfo.isID();

export function getAdditionalMenuItems(
	t: TFunction,
	counters?: NotificationCounters,
	currentMember?: CommunityItem
): readonly MenuItem[] {
	const workspace = getWorkspaceInfo();
	const isAdminOrOwner = workspace?.role === 'ADMIN' || workspace?.role === 'OWNER';

	const additionalItems: MenuItem[] = [
		{
			id: ROUTES.PROJECTS,
			title: t('modules.module4'),
			route: '/projects',
			notificationCounter: counters?.projects,
			matomoKey: MATOMO_KEYS.MY_WORK_PROJECT,
			icon: Projects,
		},
		{
			id: ROUTES.PLANS,
			title: t('modules.modules4_plans'),
			route: '/plans',
			notificationCounter: counters?.plans,
			matomoKey: MATOMO_KEYS.MY_WORK_PLANS,
			icon: Plans,
		},
		{
			id: ROUTES.COMPANY,
			title: t('modules.module_company'),
			icon: Company,
			route: '/people/org-structure',
			notificationCounter: counters?.structure,
			matomoKey: MATOMO_KEYS.TEAM_ORG_STRUCTURE,
		},
		{
			id: ROUTES.TIMESHEETS,
			title: t('modules.module3_timesheet'),
			route: '/people/timesheets',
			notificationCounter: counters?.timesheet,
			matomoKey: MATOMO_KEYS.TEAM_TIMESHEET,
			icon: Timesheets,
		},
		{
			id: ROUTES.MEETING_MINUTES,
			title: t('modules.module2_meetings_notes'),
			route: '/productivity/moms',
			notificationCounter: (counters?.meetingNotes || 0) + (counters?.meetingNotesStatePendingApproval || 0),
			matomoKey: MATOMO_KEYS.MEETINGS_NOTES,
			icon: MeetingMinutes,
		},
		{
			id: ROUTES.DASHBOARD,
			title: t('modules.dashboards'),
			icon: Dashobards,
			route: '/dashboard',
			notificationCounter: 0,
			hasPermission: () => hasPermissionToView([{ type: PERMISSIONS_TYPES.DASHBOARD, actions: [PERMISSIONS_ACTIONS.READ] }]),
		},
		{
			id: ROUTES.CNB,
			title: t('modules.module_cnb'),
			icon: Cnb,
			route: '/people/hr/cnb',
			notificationCounter: counters?.hr,
			matomoKey: MATOMO_KEYS.MY_WORK_DISK,
			hasPermission: () =>
				hasPermissionToView([
					{ type: PERMISSIONS_TYPES.CNB_OWN_GROUP, actions: [PERMISSIONS_ACTIONS.READ] },
					{ type: PERMISSIONS_TYPES.CNB_GROUP, actions: [PERMISSIONS_ACTIONS.READ] },
				]),
		},
		{
			id: ROUTES.HOLDING,
			title: t('modules.module_holding'),
			route: '/people/holding-structure',
			matomoKey: MATOMO_KEYS.TEAM_HOLDING_STRUCTURE,
			notificationCounter: 0,
			icon: Holding,
			hasPermission: () => workspace?.isHolding || workspace?.isSubsidiary,
		},
		{
			id: ROUTES.COMMUNITY,
			icon: Marketplace,
			route: '/marketplace',
			isBeta: true,
			title: t('modules.module_marketplace'),
			notificationCounter: 0,
			hasPermission: () =>
				!!currentMember ||
				hasPermissionToView([{ type: PERMISSIONS_TYPES.MARKETPLACE_PROFILE, actions: [PERMISSIONS_ACTIONS.READ] }]),
		},
		{
			id: ROUTES.DRIVE,
			title: t('modules.module5_drive'),
			route: '/drive',
			isBeta: true,
			matomoKey: MATOMO_KEYS.MY_WORK_DISK,
			icon: Drive,
			notificationCounter: 0,
		},
		{
			id: ROUTES.BUDGET,
			title: t('modules.module5_budget'),
			icon: Budget,
			route: '/budget',
			matomoKey: MATOMO_KEYS.MY_WORK_BUDGETS,
			isBeta: true,
			notificationCounter: counters?.budget,
		},
		{
			id: ROUTES.LMS,
			icon: LMS,
			route: '/lms',
			matomoKey: MATOMO_KEYS.MY_WORK_LMS,
			title: t('modules.module_lms'),
			isBeta: true,
			notificationCounter: counters?.lms,
		},
	];

	const indexToInsertDocflowItems = additionalItems.findIndex((item) => item.id === ROUTES.COMPANY);
	if (!isProdEuID) {
		additionalItems.splice(
			indexToInsertDocflowItems,
			0,
			{
				id: ROUTES.DOC_FLOW,
				title: t('modules.module_docflow'),
				icon: Docflow,
				matomoKey: MATOMO_KEYS.DOC_FLOW,
				route: '/docflow/documents',
				notificationCounter: 0,
			},
			{
				id: ROUTES.DOC_FLOW_TEMPLATES,
				title: t('modules.module_docflow_templates'),
				icon: DocflowTemplates,
				matomoKey: MATOMO_KEYS.DOC_FLOW,
				route: '/docflow/templates',
				notificationCounter: 0,
			},
			{
				id: ROUTES.DOC_FLOW_COUNTERPARTIES,
				title: t('modules.module_docflow_counterparties'),
				icon: DocflowCounterparties,
				matomoKey: MATOMO_KEYS.DOC_FLOW,
				route: '/docflow/counterparties',
				notificationCounter: 0,
			},
			{
				id: ROUTES.DOC_FLOW_LEGAL_FORMS,
				title: t('modules.module_docflow_legal_forms'),
				icon: DocflowLegalForms,
				matomoKey: MATOMO_KEYS.DOC_FLOW,
				route: '/docflow/legal-forms',
				notificationCounter: 0,
			}
		);
	}

	return [...additionalItems];
}

export function getDefaultMenuItems(t: TFunction, counters?: NotificationCounters): readonly MenuItem[] {
	return [
		{
			id: ROUTES.HOME,
			title: t('modules.module_home'),
			icon: Home,
			route: '/overview',
			matomoKey: MATOMO_KEYS.HOME,
			notificationCounter: 0,
		},
		{
			id: ROUTES.CALENDAR,
			title: t('modules.module2_meetings'),
			icon: Calendar,
			route: '/productivity/meetings',
			notificationCounter: counters?.meetings,
			matomoKey: MATOMO_KEYS.MEETINGS_MEETINGS,
		},
		{
			id: ROUTES.TASKS,
			icon: Tasks,
			title: t('modules.module2_tasks'),
			route: '/productivity/tasks',
			notificationCounter: counters?.tasks,
			matomoKey: MATOMO_KEYS.MY_WORK_TASKS,
		},
		{
			id: ROUTES.REQUESTS,
			icon: Requests,
			title: t('modules.module5_processes'),
			route: '/processes',
			notificationCounter: counters?.processes,
			matomoKey: MATOMO_KEYS.MY_WORK_PROCESSES,
		},
	];
}
