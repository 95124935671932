import { AssignEmployeeToCompanyRequest, Structure, Workspace } from '@dar/api-interfaces';

import { environment } from '../environments/environment';
import { httpClient } from './clients';

export const getEmployeeWorkspaces = (id: string) => {
	return httpClient.get<Workspace[]>(`${environment().hcmsApiRoot}/main-api/structure/${id}/workspaces`).then((res) =>
		res?.data.map((workspace) => {
			if (!workspace.organization?.shortName) {
				return {
					...workspace,
					shortName: workspace.organization.name,
					isLicenced: environment().licensedCompanies.includes(workspace.id),
				};
			}
			return {
				...workspace,
				isLicenced: environment().licensedCompanies.includes(workspace.id),
			};
		})
	);
};

export const assignEmployeeToCompany = (data: AssignEmployeeToCompanyRequest) => {
	return httpClient
		.put<Structure>(`${environment().dmsBaseApi}/hcms/main-api/structure/${data.structureId}/invited`, data)
		.then((res) => res.data);
};
