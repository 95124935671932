import React, { PropsWithChildren, ReactElement } from 'react';
import { TooltipProps } from '@material-ui/core';

import { Tooltip } from '../../../components/shared/tooltip/tooltip';
import { MenuItemProps } from '../../menu-item-types';

type MenuItemTooltipProps = Pick<MenuItemProps, 'title'> & Pick<TooltipProps, 'placement' | 'arrow'> & {
  isHidden: boolean;
};

export function MenuItemTooltip({
  children,
  isHidden,
	title,
  arrow = true,
  placement = 'right',
}: PropsWithChildren<MenuItemTooltipProps>): ReactElement {
	return (
		<Tooltip title={title} placement={placement} arrow={arrow} isHidden={isHidden}>
      {children as ReactElement}
		</Tooltip>
	);
};
