import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgReceiptMoneyRegular({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M4.167 5.208a1.875 1.875 0 011.875-1.875h7.083A1.875 1.875 0 0115 5.208v6.459h2.917v2.708a2.708 2.708 0 01-2.709 2.708h-4.375v-1.25h2.917V5.208a.625.625 0 00-.625-.625H6.042a.625.625 0 00-.625.625v6.459h-1.25V5.208zM15 15.833h.208a1.458 1.458 0 001.459-1.458v-1.458H15v2.916zm-3.125-2.083h-1.042c0-.45-.145-.89-.416-1.25h1.458a.625.625 0 010 1.25zM6.667 7.292a.625.625 0 01.625-.625h4.583a.625.625 0 110 1.25H7.292a.625.625 0 01-.625-.625zm0 2.916a.625.625 0 01.625-.625h4.583a.625.625 0 110 1.25H7.292a.625.625 0 01-.625-.625zM.833 13.75a1.25 1.25 0 011.25-1.25H8.75A1.25 1.25 0 0110 13.75v3.333a1.25 1.25 0 01-1.25 1.25H2.083a1.25 1.25 0 01-1.25-1.25V13.75zm8.334.417a.833.833 0 01-.834-.834H7.5A1.666 1.666 0 009.167 15v-.833zm0 1.666A1.667 1.667 0 007.5 17.5h.833a.833.833 0 01.834-.833v-.834zm-6.667-2.5a.833.833 0 01-.833.834V15a1.667 1.667 0 001.666-1.667H2.5zm.833 4.167a1.667 1.667 0 00-1.666-1.667v.834a.834.834 0 01.833.833h.833zm3.542-2.083a1.458 1.458 0 10-2.917 0 1.458 1.458 0 002.917 0z"
        fill="#262842"
      />
    </svg>
  );
}

const MemoSvgReceiptMoneyRegular = React.memo(SvgReceiptMoneyRegular);
export default MemoSvgReceiptMoneyRegular;
