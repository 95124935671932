import { init } from 'i18next';
import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { Toaster, theme } from '@dartech/dms-ui';
import { CssBaseline, StylesProvider, ThemeProvider, createGenerateClassName } from '@material-ui/core';

import { ChatBotProviderWrapper } from './components/chat-bot-provider/chat-bot-provider';
import './i18';
import { STYLES_PREFIX } from './shared/constants';
import './styles.scss';
import { App } from './topbar/app';
import { AuthProvider } from './topbar/providers/auth-provider';
import { BrandProvider } from './topbar/providers/brand-provider';

type langType = 'en' | 'kz' | 'ru';

const generateClassName = createGenerateClassName({
	productionPrefix: STYLES_PREFIX,
	seed: STYLES_PREFIX,
});

const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

init();

const Root = () => {
	const lang = localStorage.getItem('i18nextLng');

	return (
		<ThemeProvider theme={theme}>
			<StyledThemeProvider theme={theme}>
				<Suspense fallback={null}>
					<StylesProvider generateClassName={generateClassName}>
						<QueryClientProvider client={queryClient}>
							<BrowserRouter>
								<AuthProvider>
									<BrandProvider>
										<ChatBotProviderWrapper>
											<CssBaseline />
											<Toaster locale={lang as langType} />
											<App />
										</ChatBotProviderWrapper>
									</BrandProvider>
								</AuthProvider>
							</BrowserRouter>
						</QueryClientProvider>
					</StylesProvider>
				</Suspense>
			</StyledThemeProvider>
		</ThemeProvider>
	);
};

export { Root };
