import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import routes from './app-lazy';

interface OnbordingProps {
	authenticated: boolean;
	invited: boolean;
}

const Onbording = ({ authenticated, invited }: OnbordingProps) => {
	const { pathname } = useLocation();

	return (
		<Suspense fallback>
			<Switch>
				<Route path='/signup' component={routes.Signup} />
				<Route path='/signin' component={routes.Signin} />
				<Route path='/auth/from-landing' component={routes.LoginFromLanding} />
				<Route path='/auth/from-landing-social' component={routes.LoginFromLandingSocial} />
				<Route path='/auth/social' component={routes.LoginSocial} />
				<Route path='/auth/social-mobile' component={routes.LoginSocialMobile} />
				<Route path='/signup-code' component={routes.SignupCode} />
				<Route path='/signup-social-code' component={routes.SignUpSocialCode} />
				<Route path='/resetPassword/:username?' component={routes.ResetPassword} />
				<Route path='/reset-password-code' component={routes.ResetPasswordCode} />
				<Route path='/updatePassword' component={routes.UpdatePassword} />
				<Route path='/create-password/:sid' component={routes.CreatePassword} />
				<Route path='/invitation/:invitationId' component={routes.InvitationOnboarding} />
				<Route path='/congratulations' component={routes.Congrats} />
				<Route path='/join-meeting/:meetingId' component={routes.JoinMeeting} />
				<Route path='/docflow-sign' component={routes.Docflow} />
				{authenticated !== null && <Redirect exact from='*' to={`/signin?authRedirect=${pathname}`} />}
			</Switch>
		</Suspense>
	);
};

export { Onbording };
