import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { IChatControlContext } from '../shared/constants';

export type UseChat = {
	toggleChat: () => void;
	onChatBotClick: () => void;
	chatControl: IChatControlContext;
};

export function useChat(useChatControl: () => IChatControlContext): UseChat {
	const history = useHistory();
	const chatControl = useChatControl();

	const toggleChat = useCallback(() => {
		chatControl.toggleDrawer();
	}, [chatControl.toggleDrawer]);

	const onChatBotClick = useCallback(() => {
		toggleChat();
		window.location.hash = '#/bot';
	}, [history, toggleChat]);

	useEffect(() => {
		if (!chatControl.isDrawerOpen && window.location.hash.includes('#/bot')) {
			window.location.hash = window.location.hash.replace('#/bot', '');
		}
	}, [chatControl.isDrawerOpen]);

	return {
		toggleChat,
		onChatBotClick,
		chatControl,
	};
}
