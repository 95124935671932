import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { NotificationCounters } from '@dar/api-interfaces';
import { getAdditionalMenuItems, getDefaultMenuItems } from '@topbar/src/components/sidebar/sidebar-constants';
import { UseCurrentCommunity } from '@topbar/src/hooks/useCurrentCommunity';

export function useMenuItems(counters: NotificationCounters, t: TFunction) {
	const location = useLocation();
	const { currentMember } = UseCurrentCommunity();

	const defaultMenuItems = useMemo(() => getDefaultMenuItems(t, counters), [t, counters]);
	const additionalMenuItems = useMemo(
		() =>
			getAdditionalMenuItems(t, counters, currentMember).filter((additionalItem) =>
				typeof additionalItem.hasPermission === 'function' ? additionalItem.hasPermission() : true
			),
		[t, counters, currentMember]
	);

	const activeMenuItem = useMemo(() => additionalMenuItems.find((item) => location?.pathname?.includes(item.route)), [
		additionalMenuItems,
		location,
	]);

	return {
		additionalMenuItems,
		defaultMenuItems,
		activeMenuItem,
	};
}
