import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';

import { Box, IconButton } from '@material-ui/core';
// import { ReactComponent as PersonAddIcon } from '@topbar/src/assets/person_add_regular.svg';
// import { ReactComponent as GlobeAddIcon } from '@topbar/src/assets/icons/globe_add_regular.svg';
import { ReactComponent as ChatIcon } from '@topbar/src/assets/icons/chat_regular.svg';
import { ReactComponent as SettingsIcon } from '@topbar/src/assets/icons/settings_regular.svg';
import { PERMISSIONS_TYPES } from '@topbar/src/constants/permissions';
import { WORKSPACE_STORAGE_KEY, WORKSPACE_TOKEN_STORAGE_KEY } from '@topbar/src/constants/workspace';
import { checkPermission } from '@topbar/src/utils/permissions';

import useStyles from '../style';
import { settingsMenuConfig } from '../utils';
// import { useHistory } from 'react-router-dom';
import { MenuSettings } from './menu-settings';

interface Props {
	isMini: boolean;
	variant: 'light' | 'dark';
}

const isProd = window.location.origin.includes('darlean.id') || window.location.origin.includes('darlean.kz');

const Settings = ({ isMini, variant }: Props) => {
	const classes = useStyles({ isMini, variant });
	// const history = useHistory();
	const bitrixWrapper: HTMLElement = document.querySelector('.b24-widget-button-wrapper');
	const bitrixButton: HTMLElement = document.querySelector('.b24-widget-button-icon-container');
	const tokenDecodablePart = JSON.parse(localStorage.getItem(WORKSPACE_STORAGE_KEY));
	const role = tokenDecodablePart?.role;

	const workspaceToken = localStorage.getItem(WORKSPACE_TOKEN_STORAGE_KEY);
	const decodedWorkspace: { permissions: any } = workspaceToken && jwtDecode(workspaceToken);
	const permissions = decodedWorkspace?.permissions;

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	useEffect(() => {
		if (bitrixWrapper) {
			bitrixWrapper.style.display = 'none';
		}
	}, []);

	const onChatClick = () => {
		bitrixButton.click();
	};

	return (
		<Box p='12px'>
			<Box className={classes.settingsButtons}>
				{(['ADMIN', 'OWNER'].includes(role) || checkPermission([PERMISSIONS_TYPES.WORKSPACE_CALENDAR], permissions)) && (
					<IconButton className={classes.icon} onClick={handleMenu}>
						<SettingsIcon />
					</IconButton>
				)}
				{!isProd && (
					<IconButton className={classes.icon} onClick={onChatClick}>
						<ChatIcon />
					</IconButton>
				)}
				{/* <IconButton className={classes.icon}>
					<GlobeAddIcon />
				</IconButton>
				<IconButton className={classes.icon}>
					<PersonAddIcon />
				</IconButton> */}
			</Box>
			{
				/* <SettingsMenu anchorEl={anchorEl} onClose={handleClose} isOpen={open} /> */
				<MenuSettings
					name='hello'
					anchorEl={anchorEl}
					onClose={handleClose}
					settings={{
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						transformOrigin: {
							vertical: 'bottom',
							horizontal: 'right',
						},
					}}
					data={settingsMenuConfig}
				/>
			}
		</Box>
	);
};

export { Settings };
