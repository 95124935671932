import jwtDecode from 'jwt-decode';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { UserLanguage, Workspace } from '@dar/api-interfaces';
import { UserAvatar } from '@dartech/dms-ui';
import { Profile as IProfile, WORKSPACE_TOKEN_STORAGE_KEY } from '@dartech/griffon-auth';
import { Box, Divider, ListItemIcon, Menu, MenuItem, Typography, makeStyles } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ReactComponent as GlobeIcon } from '@topbar/src/assets/icons/book_globe_regular.svg';
import { ReactComponent as LogoutIcon } from '@topbar/src/assets/icons/door_arrow_left_regular.svg';
import { ReactComponent as GlobeDesktopIcon } from '@topbar/src/assets/icons/globe_desktop_regular.svg';
import { ReactComponent as PersonNoteIcon } from '@topbar/src/assets/icons/person_note_regular.svg';
import { PERMISSIONS_TYPES } from '@topbar/src/constants/permissions';
import { WORKSPACE_STORAGE_KEY } from '@topbar/src/constants/workspace';
import { getLanguages, setLanguage } from '@topbar/src/services/language';
import { useAuth } from '@topbar/src/topbar/providers/auth-provider';
import { checkPermission } from '@topbar/src/utils/permissions';
import { toSentenceCase } from '@topbar/src/utils/profile';

import useStyles from '../style';
import { LanguageMenu } from './language-menu';

const useOwnStyles = makeStyles((theme) => ({
	paper: {
		transform: 'translate(5%, 0px) !important',
		boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
		borderRadius: '0px 0px 4px 4px',
		border: '1px solid #E0E3EA',
		width: '320px',
	},
}));

const ProfileMenu = ({ anchorEl, onClose, open, isMini, variant, activeWorkspace, profile, data }) => {
	const ownClasses = useOwnStyles();
	const classes = useStyles({ isMini, variant });
	const history = useHistory();
	const { t, i18n } = useTranslation();
	const { logout } = useAuth();

	const [langs, setLangs] = useState([]);

	const workspaceToken = localStorage.getItem(WORKSPACE_TOKEN_STORAGE_KEY);
	const decodedWorkspace: { permissions: any } = workspaceToken && jwtDecode(workspaceToken);
	const permissions = decodedWorkspace?.permissions;

	const tokenDecodablePart = JSON.parse(localStorage.getItem(WORKSPACE_STORAGE_KEY));
	const roles = tokenDecodablePart?.role;

	const [languageAnchorEl, setLanguageAnchorEl] = useState<null | HTMLElement>(null);

	const { mutate: changeLanguage, isLoading: languageLoading } = useMutation((payload: UserLanguage) => setLanguage(payload), {
		onSuccess: (payload: UserLanguage) => {
			i18n.changeLanguage(payload?.language?.toLowerCase());
			window.location.reload();
		},
	});

	const handleLanguageSelect = (lang: string) => {
		i18n.changeLanguage(lang);
		const data: UserLanguage = {
			userId: profile?.id,
			language: lang.toUpperCase(),
		};
		changeLanguage(data);
		setLanguageAnchorEl(null);
	};

	const handleLanguageMenuClose = () => {
		setLanguageAnchorEl(null);
	};

	const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setLanguageAnchorEl(event.currentTarget);
	};

	const { isLoading } = useQuery({
		queryFn: getLanguages,
		onSuccess: (data) => {
			setLangs(data);
		},
	});

	return (
		<Menu
			id='menu-appbar'
			anchorEl={anchorEl}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			keepMounted
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={open}
			onClose={onClose}
			MenuListProps={{
				style: { padding: '8px 0', width: '100%' },
			}}
			classes={ownClasses}
		>
			<MenuItem className={classes.menuItem}>
				<ListItemIcon className={classes.listItemIcon}>
					<UserAvatar
						size={32}
						fontSize={14}
						userName={[data?.name.first, data?.name.last || ' ']}
						src={data?.photo}
						className={classes.avatar}
					/>
				</ListItemIcon>
				<Box display='flex' flexDirection='column'>
					<Typography className={classes.textName}>{profile?.first_name + ' ' + profile?.last_name}</Typography>
					<Typography className={classes.textEmail}>{profile?.email}</Typography>
					{activeWorkspace?.role ? (
						<Typography className={classes.textRole}>{toSentenceCase(activeWorkspace?.role)}</Typography>
					) : null}
				</Box>
			</MenuItem>
			<Divider />
			<MenuItem
				className={classes.item}
				onClick={() => {
					history.push(`/people/profile/${profile?.id}/profile`);
					onClose();
				}}
			>
				<PersonNoteIcon />
				<Typography variant='inherit'>{t('menu.field2')}</Typography>
			</MenuItem>
			{(roles === 'ADMIN' ||
				roles === 'OWNER' ||
				checkPermission(
					[PERMISSIONS_TYPES.USER_AND_TEAMS, PERMISSIONS_TYPES.DIRECTORIES, PERMISSIONS_TYPES.COMPANY_PROFILE],
					permissions
				)) && (
				<MenuItem
					className={classes.item}
					onClick={() => history.push('/admin', { from: history.location.pathname + history.location.search })}
				>
					<GlobeDesktopIcon />
					<Typography variant='inherit'>{t('menu.field5')}</Typography>
				</MenuItem>
			)}
			<MenuItem onClick={handleLanguageMenuOpen} className={classes.item} style={{ justifyContent: 'space-between' }}>
				<Box display='flex' flexDirection='row' alignItems='center' style={{ gap: '8px' }}>
					<GlobeIcon />
					<Typography className={classes.company}>{t('menu.field3')}</Typography>
				</Box>
				<Box>
					<ArrowForwardIosIcon style={{ width: '60%', color: '#6D7C8B' }} />
				</Box>
			</MenuItem>
			<LanguageMenu anchorEl={languageAnchorEl} onClose={handleLanguageMenuClose} onSelect={handleLanguageSelect} langs={langs} />
			<Divider />
			<MenuItem className={classes.item} onClick={logout}>
				<LogoutIcon /> <Typography variant='inherit'>{t('menu.field4')}</Typography>
			</MenuItem>
		</Menu>
	);
};

export { ProfileMenu };
