import React, { FC } from 'react';

import { Button, MenuItem } from '@mui/material';
import { ReactComponent as LanguageIcon } from '@topbar/src/assets/auth/language.svg';

import { BackButton } from '../back-button';
import Styled from './style';
import { useAuthHeader } from './useAuthHeader';

interface Props {
	showLogin?: boolean;
	showSignup?: boolean;
	showLogo?: boolean;
	hasBackButton?: boolean;
}

const AuthHeader: FC<Props> = ({ showLogin = false, showSignup = false, showLogo = false, hasBackButton = false }) => {
	const { anchorEl, currentLang, isOpen, onChange, onClick, onModalOpen, t, onModalClose, languages } = useAuthHeader();

	return (
		<Styled.Header showLogo={showLogo}>
			{hasBackButton ? <BackButton /> : <Styled.LogoIcon show={showLogo} />}
			<div className='buttons'>
				<Styled.LangButton variant='text' color='secondary' startIcon={<LanguageIcon />} onClick={onModalOpen}>
					{currentLang.id}
				</Styled.LangButton>
				<Styled.Menu
					open={isOpen}
					anchorEl={anchorEl}
					onClose={onModalClose}
					autoFocus
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					{languages.map((lang) => (
						<MenuItem key={lang.id} value={lang.id} data-lang={lang.id} onClick={onChange}>
							{lang.name}
						</MenuItem>
					))}
				</Styled.Menu>
				{showLogin && (
					<Button variant='outlined' color='secondary' data-path={'/signin'} onClick={onClick}>
						{t('login.login_login_btn')}
					</Button>
				)}
				{showSignup && (
					<Button variant='outlined' color='secondary' data-path={'/signup'} onClick={onClick}>
						{t('login.login_signup_btn')}
					</Button>
				)}
			</div>
		</Styled.Header>
	);
};

export default AuthHeader;
