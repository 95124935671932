import { makeStyles } from 'tss-react/mui';

import { CSSProperties } from '@mui/styles';

import { sidebarTheme } from '../../../sidebar-theme';

export const MODAL_PADDING = parseInt(sidebarTheme.spacing['positive-100']);
export const MODAL_LIST_VERTICAL_PADDING = parseInt(sidebarTheme.spacing['positive-100']);
export const MODAL_BORDER_WIDTH = 1;

export const useOwnStyles = makeStyles()({
	paper: {
		width: '250px',
		boxShadow: '0px 8px 16px 0px #2628421A',
		border: `${MODAL_BORDER_WIDTH}px solid #2628421F`,
		padding: `${MODAL_PADDING}px`,
		borderRadius: '10px',
		maxHeight: 'unset',
		left: '12px !important',
	},
	list: {
		padding: `${MODAL_LIST_VERTICAL_PADDING}px 0 0`,
		width: '100%',
		position: 'relative',
	},
});

export const onDragStyles: CSSProperties = { width: '100%' };
