import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import Backend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

import { environment } from '@topbar/src/environments/environment';

import { asyncLocalStorage } from '../utils/async-local-storage';

const langVersion = 'v1.3';
const namespace = 'weblayout';
const loadPath = `${environment().dmsBaseApi}/dictionary-api/localization/all?lang={{lng}}&module={{ns}}`;
const defaultLanguage = localStorage.getItem('i18nextLng') || environment().defaultLanguage || 'en';

i18n
	.use(detector)
	.use(ChainedBackend)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		ns: [namespace, 'hcms', 'admin'],
		lng: defaultLanguage,
		defaultNS: namespace,
		backend: {
			backends: [LocalStorageBackend, Backend],
			backendOptions: [
				{
					expirationTime: 7 * 24 * 60 * 60 * 1000,
					versions: environment().supportedLangs.reduce((acc, cur) => ({ ...acc, [cur]: langVersion }), {}),
				},
				{
					loadPath,
				},
			],
		},
		supportedLngs: environment().supportedLangs,
		fallbackLng: 'en', //if resources not found use "en"
		interpolation: {
			escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		},
	});

i18n.on('languageChanged', (lang) => {
	const localStorageKeys = Object.keys(localStorage);

	// it is the fastest loop
	for (let i = 0; i < localStorageKeys.length; i++) {
		// check if key contains translation prefix, then check if it is not of currently selected or fallback languages
		if (
			localStorageKeys[i].includes('i18next_res_') &&
			!localStorageKeys[i].includes(`i18next_res_${lang}`) &&
			!localStorageKeys[i].includes(`i18next_res_en`)
		) {
			// defer local storage remove operation until the call stack is empty
			asyncLocalStorage.removeItem(localStorageKeys[i]);
		}
	}
});

export default i18n;
