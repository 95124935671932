import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

// import { SideMenuHeader } from '@dartech/dms-ui';
import { Workspace } from '@dar/api-interfaces';
import { WORKSPACE_TOKEN_STORAGE_KEY } from '@dartech/griffon-auth';
import { Avatar, Box, Typography, makeStyles } from '@material-ui/core';
import { WORKSPACE_STORAGE_KEY } from '@topbar/src/constants/workspace';
import { getEmployeeWorkspaces } from '@topbar/src/services/companies';
import { getLanguage } from '@topbar/src/services/language';
import { getWorkspaceToken } from '@topbar/src/services/workspace';

import { useAuth } from '../../../topbar/providers/auth-provider';
import useStyles from '../style';
import { takeShortName } from '../utils';
import { WorkspacesMenu } from './workspaces-menu';

interface Props {
	isMini: boolean;
	workspace: Workspace;
	variant: 'light' | 'dark';
}

const useOwnStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#497CF6',
		width: '32px',
		height: '32px',
		fontSize: '14px',
		border: '1px solid rgba(255, 255, 255, 0.6)',
		borderRadius: '6px',
	},
}));

const Header = ({ isMini, workspace: activeWorkspace, variant }: Props) => {
	const classes = useStyles({ isMini, variant });
	const ownClasses = useOwnStyles();
	const { profile, logout, setWorkspace } = useAuth();
	const history = useHistory();

	const [workspaceAnchorEl, setWorkspaceAnchorEl] = useState<null | HTMLElement>(null);
	const workspaceMenuOpen = Boolean(workspaceAnchorEl);
	const [workspaces, setWorkspaces] = useState<Array<Workspace>>([]);

	const fetchData = async () => getEmployeeWorkspaces(profile?.id);

	const handleWorkspaceMenuClose = () => {
		setWorkspaceAnchorEl(null);
	};

	const { mutate } = useMutation(fetchData, {
		onSuccess: (workspaces) => {
			setWorkspaces(workspaces);
		},
		onError: () => {
			logout();
		},
	});

	const { mutate: mutateLang } = useMutation(getLanguage);

	const handleWorkspaceSelect = async (workspace: Workspace) => {
		await fetchWorkspaceToken(workspace);
		setWorkspaceAnchorEl(null);
		setWorkspace(workspace);
		localStorage.setItem(WORKSPACE_STORAGE_KEY, JSON.stringify(workspace));
		if (history.location.pathname.includes('upgrade') || history.location.pathname.includes('forbidden')) {
			window.location.replace('/overview');
		} else {
			window.location.reload();
		}
	};

	const fetchWorkspaceToken = async (activeWorkspace: Workspace) => {
		const tokenResponse = await getWorkspaceToken(activeWorkspace?.id, profile?.id);
		localStorage.setItem(WORKSPACE_TOKEN_STORAGE_KEY, tokenResponse?.token);
	};

	const handleWorkspaceMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setWorkspaceAnchorEl(event.currentTarget);
	};

	useEffect(() => {
		profile?.id?.length && mutate();
		mutateLang(profile.id);
		// eslint-disable-next-line
	}, [profile.id]);

	return (
		<>
			<Box className={`${classes.workspaceWrapper} ${workspaceMenuOpen && 'opened'}`} onClick={handleWorkspaceMenuOpen}>
				<Box className={classes.workspaceContainer}>
					{activeWorkspace?.organization?.logo ? (
						<img
							src={activeWorkspace?.organization.logo}
							alt={activeWorkspace?.organization.shortName}
							// onError={() => setShowLogo(false)}
							className={classes.logoWorkspace}
						/>
					) : (
						<Avatar classes={ownClasses}>{takeShortName(activeWorkspace?.organization.shortName)}</Avatar>
					)}
					{!isMini && (
						<Box ml='10px'>
							<Typography className={classes.workspaceFullName}>{activeWorkspace?.organization.shortName}</Typography>
						</Box>
					)}
				</Box>
			</Box>

			<WorkspacesMenu
				isOpen={workspaceMenuOpen}
				anchorEl={workspaceAnchorEl}
				onSelect={handleWorkspaceSelect}
				onClose={handleWorkspaceMenuClose}
				activeWorkspace={activeWorkspace}
				workspaces={workspaces}
				isMini={isMini}
				variant={variant}
			/>
		</>
	);
};

export { Header };
