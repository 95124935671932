import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Divider, Grid, Menu, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as AddIcon } from '@topbar/src/assets/icons/globe_add_regular.svg';
import { MenuAutoCompleteSelect } from '@topbar/src/pages/user-settings/components/menu-auto-complete-select';
import { useAuth } from '@topbar/src/topbar/providers/auth-provider';

import useStyles from '../style';

const useMenuStyles = makeStyles((theme) => ({
	paper: {
		borderRadius: '10px',
		border: '1px solid rgba(38, 40, 66, 0.08)',
		maxHeight: '100%',
		zIndex: 1200,
		transform: 'translate(2%, 0px) !important',
		width: '400px',
		boxShadow: '0px 8px 16px rgb(38 40 66 / 4%)',
	},
	list: {
		padding: '0',
		width: '100%',
	},
}));

const WorkspacesMenu = ({ onClose, isOpen, activeWorkspace, anchorEl, workspaces, onSelect, isMini, variant }) => {
	const menuClasses = useMenuStyles();
	const classes = useStyles({ isMini, variant });
	const { profile } = useAuth();
	const { t } = useTranslation();

	return (
		<Menu
			id='menu-companies'
			disableEnforceFocus
			disableRestoreFocus
			anchorEl={anchorEl}
			getContentAnchorEl={null}
			classes={menuClasses}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			open={isOpen}
			onClose={onClose}
		>
			<Box>
				<MenuAutoCompleteSelect activeCompany={activeWorkspace} onSelect={onSelect} open={isOpen} options={workspaces} />
			</Box>
			<Divider />
			<Box className={classes.workspaceMenuContainer}>
				<Link to={`/create-workspace/BUSINESS/${profile?.email}/false`} className={classes.link}>
					<Grid container alignItems='center'>
						<Grid item alignItems='center' style={{ display: 'flex' }}>
							<AddIcon className={classes.globe} />
						</Grid>
						<Grid item>
							<Typography className={classes.workspaceButton}>{t('login.login_new_wrkspc')}</Typography>
						</Grid>
					</Grid>
				</Link>
			</Box>
		</Menu>
	);
};

export { WorkspacesMenu };
