import styled from '@emotion/styled';
import { Box } from '@material-ui/core';

import { Button } from '../components/shared/button/button';
import { sidebarTheme } from '../sidebar-theme';

export const CHAT_SECTION_SIZE_PX = 76;
export const CHAT_BUTTON_SIZE = 32;
export const SIDEFAR_FOOTER_COLLAPSED_HEIGHT = 144;

const Container = styled(Box)`
	align-items: center;
	border-top: ${sidebarTheme.border.default};
	min-height: 56px;
	padding: ${sidebarTheme.spacing['positive-150']};
	display: flex;
	justify-content: space-between;
	position: relative;
	will-change: min-height, flex-direction;
`;

const ChatSection = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: ${CHAT_SECTION_SIZE_PX}px;
	will-change: width, height, flex-direction;
`;

const ChatButton = styled(Button)`
	--chat-icon-fill: none;

	will-change: height;

	svg {
		fill: var(--chat-icon-fill, none);
	}

	&:hover {
		--chat-icon-fill: #2628420a;

		cursor: pointer;
	}

	&:active {
		--chat-icon-fill: #26284214;
	}

	&:focus-visible {
		outline-color: #0072b8;
	}
`;

const CollapseButton = styled(Button)`
	--collapse-icon-fill: none;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	will-change: opacity, display;

	&:hover {
		cursor: pointer;
	}

	svg {
		fill: var(--collapse-icon-fill, none);
	}

	&:hover {
		--collapse-icon-fill: #2628420a;

		cursor: pointer;
	}

	&:active {
		--collapse-icon-fill: #26284214;
	}

	&:focus-visible {
		outline-color: #0072b8;
	}
`;

export const StyledFooter = {
	Container,
	ChatButton,
	CollapseButton,
	ChatSection,
};
