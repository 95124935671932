import { useQuery } from 'react-query';

import { NotificationCounters } from '@dar/api-interfaces';
import { Profile } from '@dartech/griffon-auth';
import { setCounter } from '@topbar/src/hooks/useNotificationsCounter';
import { notificationsService } from '@topbar/src/services/notifications';

const REFETCH_INTERVAL_USE_COUNTERS = 2000;
const QUERY_KEY_USE_COUNTERS = 'notificationsCounters';

export function useCounters(profileId: Profile['id'], companyId: string): NotificationCounters {
	const { data: counters } = useQuery({
		queryKey: QUERY_KEY_USE_COUNTERS,
		queryFn: () => notificationsService?.getNotificationsCounters(profileId, companyId),
		refetchInterval: REFETCH_INTERVAL_USE_COUNTERS,
		onSuccess: (data) => setCounter(data),
	});

	return counters;
}
