import styled from '@emotion/styled';
import { MenuItem as MuiMenuItem } from '@material-ui/core';

const MenuItem = styled(MuiMenuItem)`
	display: flex;
	justifycontent: space-between;
	align-items: center;

	&:hover {
		background: rgba(38, 40, 66, 0.04);
	}

	&.Mui-selected {
		background: rgba(38, 40, 66, 0.08);

		&:hover {
			background: rgba(38, 40, 66, 0.04);
		}
	}
`;

export const StyledRegionMenu = {
	MenuItem,
};
