import React, { ReactElement, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationCounter } from '../components/shared/notification-counter/notification-counter';
import { Tooltip } from '../components/shared/tooltip/tooltip';
import { TypographyWithHint } from '../components/shared/typography-with-hint/typography-with-hint';
import { ROUTES } from '../sidebar-constants';
import { ReactComponent as Pinned } from '../svg/pinned.svg';
import { ReactComponent as Unpinned } from '../svg/unpinned.svg';
import { useMenuItemAnimations } from './hooks/use-animations';
import { StyledMenuItem } from './menu-item-styles';
import { MenuItemProps } from './menu-item-types';

export function MenuItem({
	icon: MenuItemIcon,
	title,
	isBeta,
	notificationCounter,
	onPin,
	collapsed,
	onClick,
	hasPin,
	id,
	isActive,
	shouldShortenText = false,
	route,
	className,
}: MenuItemProps): ReactElement {
	const pinIconRef = useRef<HTMLButtonElement>(null);
	const titleRef = useRef<HTMLSpanElement>(null);
	const { t } = useTranslation();

	const additionalStateContainerId = useMemo(() => `additional-state-container-${id}`, [id]);
	const shouldDisplayPin = useMemo(() => onPin !== undefined, [onPin]);
	const hasNotifications = useMemo(() => notificationCounter > 0, [notificationCounter]);
	const shortWordMaxLength = useMemo(
		() => (isBeta && hasNotifications ? 8 : isBeta || hasNotifications ? 14 : 20),
		[hasNotifications, isBeta]
	);

	const onItemPin = useCallback(
		(event: React.MouseEvent<HTMLElement, MouseEvent>, id: ROUTES) => {
			event.preventDefault();
			event.stopPropagation();

			onPin?.(id);
		},
		[onPin]
	);

	useMenuItemAnimations({
		collapsed,
		title: titleRef.current,
		additionalStateContainerId,
	});

	return (
		<Tooltip title={title} placement='right' arrow isHidden={!collapsed}>
			<StyledMenuItem.Container
				$isActive={isActive}
				$hasNotifications={collapsed && hasNotifications}
				id={String(id)}
				href={route}
				handleClick={() => onClick?.()}
				className={className}
			>
				<StyledMenuItem.IconContainer>
					<MenuItemIcon role='presentation' width={20} height={20} />
					{shouldShortenText ? (
						<TypographyWithHint
							shortWordMaxLength={shortWordMaxLength}
							text={title}
							render={(text) => <StyledMenuItem.Title ref={titleRef}>{text}</StyledMenuItem.Title>}
						/>
					) : (
						<StyledMenuItem.Title ref={titleRef}>{title}</StyledMenuItem.Title>
					)}
				</StyledMenuItem.IconContainer>
				<StyledMenuItem.AdditionalStateContainer id={additionalStateContainerId}>
					{isBeta ? <StyledMenuItem.BetaBadge>Beta</StyledMenuItem.BetaBadge> : null}
					{hasNotifications ? <NotificationCounter counter={notificationCounter} /> : null}
					{shouldDisplayPin ? (
						<Tooltip arrow title={t(hasPin ? 'general.unpin_tooltip' : 'general.pin_tooltip')} placement='top'>
							<StyledMenuItem.PinButton onClick={(event) => onItemPin(event, id)} ref={pinIconRef}>
								{hasPin ? <Pinned /> : <Unpinned />}
							</StyledMenuItem.PinButton>
						</Tooltip>
					) : null}
				</StyledMenuItem.AdditionalStateContainer>
			</StyledMenuItem.Container>
		</Tooltip>
	);
}
