import { animate } from 'motion';
import { useEffect } from 'react';

type ChatBotSectionAnimations = {
	collapsed: boolean;
	chatBotSectionContainerId: string;
  chatBotSectionTextContainerId: string;
};

export function useChatBotSectionAnimations({ collapsed, chatBotSectionTextContainerId, chatBotSectionContainerId }: ChatBotSectionAnimations): void {
	useEffect(() => {
		const chatBotSectionContainer = document.getElementById(chatBotSectionContainerId);
    const chatBotSectionTextContainer = document.getElementById(chatBotSectionTextContainerId);

		if (chatBotSectionContainer && chatBotSectionTextContainer) {
			if (collapsed) {
        animate(chatBotSectionTextContainer, { opacity: 0 }, { duration: 0.2 }).finished.then(() => {
          animate(chatBotSectionTextContainer, { display: 'none' });
        });
			} else {
        animate(chatBotSectionTextContainer, { display: 'block' }).finished.then(() => {
          animate(chatBotSectionTextContainer, { opacity: 1 }, { duration: 0.4 });
        });
			}
		}
	}, [chatBotSectionContainerId, chatBotSectionTextContainerId, collapsed]);
}
