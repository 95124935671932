import * as React from 'react';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgPeopleTeamFilled({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				d='M12.295 8.333c.805 0 1.458.654 1.458 1.459v3.957a3.75 3.75 0 01-7.501 0V9.792c0-.805.652-1.459 1.458-1.459h4.585zm-6.352 0c-.292.352-.48.792-.517 1.275l-.008.184v3.957c0 .706.16 1.374.445 1.971a3.335 3.335 0 01-4.196-3.22V9.791a1.458 1.458 0 011.338-1.454l.12-.005h2.818zm8.12 0h2.812c.805 0 1.458.654 1.458 1.459V12.5a3.333 3.333 0 01-4.191 3.222c.25-.524.403-1.1.437-1.71l.008-.263V9.792c0-.554-.197-1.063-.525-1.459zM10 2.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm5.417.833a2.083 2.083 0 110 4.167 2.083 2.083 0 010-4.167zm-10.834 0a2.083 2.083 0 110 4.167 2.083 2.083 0 010-4.167z'
				fill='#262842'
			/>
		</svg>
	);
}

const MemoSvgPeopleTeamFilled = React.memo(SvgPeopleTeamFilled);
export default MemoSvgPeopleTeamFilled;
