export const extractLetters = (user) => {
	const { first_name = '', last_name = '', nickname = '' } = user || {};
	let letters = [first_name[0], last_name[0]].filter(Boolean).join('').toUpperCase();

	if (letters.length < 1) {
		letters = nickname
			.split(' ')
			.filter(Boolean)
			.map((name) => name[0])
			.join('')
			.toUpperCase();
	}

	return letters;
};

export const toSentenceCase = (word: string) => word?.charAt(0).toUpperCase() + word?.substr(1).toLowerCase();

export const defaultUserFirstName = 'User';
