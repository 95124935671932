import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgSettingsFilled({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.01 1.875c.612.007 1.22.077 1.818.21a.625.625 0 01.485.542l.142 1.272a1.153 1.153 0 001.606.93l1.167-.512a.625.625 0 01.709.145 8.16 8.16 0 011.836 3.16.625.625 0 01-.226.687l-1.035.764a1.15 1.15 0 000 1.854l1.036.763a.625.625 0 01.227.688 8.163 8.163 0 01-1.837 3.16.625.625 0 01-.706.146l-1.173-.514a1.15 1.15 0 00-1.605.928l-.14 1.272a.625.625 0 01-.477.54 7.934 7.934 0 01-3.672 0 .625.625 0 01-.477-.54l-.14-1.27a1.152 1.152 0 00-1.605-.925l-1.171.513a.625.625 0 01-.708-.146 8.166 8.166 0 01-1.837-3.163.625.625 0 01.227-.688l1.036-.764a1.15 1.15 0 000-1.854L2.454 8.31a.625.625 0 01-.226-.689 8.16 8.16 0 011.837-3.16.625.625 0 01.708-.144l1.167.512a1.156 1.156 0 001.608-.932l.142-1.271a.625.625 0 01.486-.542 8.94 8.94 0 011.834-.21zM10 7.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
        fill="#262842"
      />
    </svg>
  );
}

const MemoSvgSettingsFilled = React.memo(SvgSettingsFilled);
export default MemoSvgSettingsFilled;
