import * as React from 'react';
import { createPortal } from 'react-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from '@emotion/styled';

import { MenuItem } from '@topbar/src/components/sidebar/menu-item/menu-item';
import type { MenuItemProps } from '@topbar/src/components/sidebar/menu-item/menu-item-types';
import type { MenuItem as IMenuItem } from '@topbar/src/components/sidebar/sidebar-constants';

const StyledMenuItem = styled(MenuItem)`
	user-select: none;
`;

const _dragEl = document.getElementById('draggable');

type ItemProps = {
	index: number;
	item: MenuItemProps;
};

function optionalPortal(styles, element) {
	if(styles && styles?.position === 'fixed') {
		return createPortal(
			element,
			_dragEl,
		);
	}

	return element;
};

const Item = ({ index, item }: ItemProps) => (
	<Draggable index={index} draggableId={item.id}>
		{(provided) => {
			return optionalPortal(provided.draggableStyle, (
				<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
					<StyledMenuItem {...item} shouldShortenText />
				</div>
			));
		}}
	</Draggable>
);

type ListProps = {
	items: readonly MenuItemProps[];
	onDragEnd: ({ destination, source }: { destination: number; source: number }) => void;
	activeRoute: IMenuItem | null;
	collapsed: boolean;
};

const List = ({ items, onDragEnd, activeRoute, collapsed }: ListProps) => {
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId='droppable'>
				{(provided) => (
					<ul ref={provided.innerRef} {...provided.droppableProps}>
						{items.map((item, index) => (
							<li key={item.id}>
								<Item
									key={item.id}
									index={index}
									item={{
										...item,
										isActive: activeRoute?.id === item.id,
										collapsed,
									}}
								/>
							</li>
						))}
					</ul>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export type DroppablePosition = {
	droppableId: string;
	index: number;
};

type DraggableListProps = {
	items: readonly MenuItemProps[];
	activeRoute: IMenuItem | null;
	collapsed: boolean;
	onReorder: (startIndex: number, endIndex: number) => void;
};

export function DraggableList({ onReorder, ...rest }: DraggableListProps) {
	const onDragEnd = ({ destination, source }) => {
		if (!destination) return;

		onReorder(source.index + 1, destination.index + 1);
	};

	return <List {...rest} onDragEnd={onDragEnd} />;
}
