import { Theme, makeStyles } from '@material-ui/core';

type Props = {
	isMini: boolean;
	variant: 'dark' | 'light';
	height?: number;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
	listItemWrapper: {
		position: 'relative',
	},
	endIcon: {
		position: 'absolute',
		right: 12,
		top: 8,
	},
	workspaceWrapper: {
		width: '100%',
		minHeight: '40px',
		padding: '12px',
		cursor: 'pointer',
		'&.opened': {
			background: 'rgba(255, 255, 255, 0.08)',
		},
	},
	logoWorkspace: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		width: '32px',
	},
	menuItem: {
		cursor: 'default',
		'&:hover': {
			background: '#fff',
		},
	},
	profileWrapper: {
		cursor: 'pointer',
		'&.opened': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		},
	},
	item: {
		display: 'flex',
		alignItems: 'center',
		padding: '15px 16px',
		gap: '8px',
		height: '50px',
		'&:hover': {
			background: 'rgba(38, 40, 66, 0.04)',
		},
	},
	workspaceMenuContainer: {
		padding: '15px 16px',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	workspaceButton: {
		marginLeft: '10px',
		fontSize: '14px',
		lineHeight: '18px',
		fontWeight: 400,
	},
	globe: {
		'& > path': {
			fill: '#007994',
		},
	},
	workspaceContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	workspaceShortName: {
		backgroundColor: '#497CF6',
		width: '32px',
		height: '32px',
		fontSize: '14px',
		border: '1px solid rgba(255, 255, 255, 0.6)',
		borderRadius: '6px',
	},
	workspaceFullName: {
		fontSize: '14px',
		color: (props) => (props.variant == 'dark' ? '#fff' : '#262842'),
	},
	profileContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: (props) => (props.isMini ? 'center' : 'flex-start'),
	},
	role: {
		fontSize: '14px',
		color: (props) => (props.variant == 'dark' ? '#fff' : '#262842'),
	},
	email: {
		fontSize: '14px',
		color: (props) => (props.variant == 'dark' ? '#F7F8FC' : '#262842'),
		opacity: '0.6',
	},
	viewButtonContainer: {
		padding: '12px',
		display: 'flex',
	},
	viewMiniButton: {
		backgroundColor: '#2DB77B',
		border: 'none',
		margin: 'auto',
		'& > span > svg > path': {
			fill: '#fff',
		},
	},
	viewButton: {
		backgroundColor: '#2DB77B',
		border: 'none',
		minHeight: '40px',
		borderRadius: '10px',
		'& > span': {
			fontWeight: 500,
		},
	},
	divider: {
		backgroundColor: (props) => (props.variant == 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'D9D9D9'),
	},
	settingsButtons: {
		display: 'flex',
		gap: '10px',
		flexDirection: (props) => (props.isMini ? 'column' : 'row'),
	},
	icon: {
		border: 'none',
		borderRadius: '8px !important',
		backgroundColor: (props) => (props.variant == 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(38, 40, 66, 0.08)'),
		'&:hover': {
			backgroundColor: 'rgba(38, 40, 66, 0.12)',
		},
		'& span svg path': {
			fill: (props) => (props.variant == 'dark' ? '#fff !important' : '#262842 !important'),
		},
	},
	avatar: {
		width: '32px',
		height: '32px',
		margin: '0px !important',
	},
	menuIcon: {
		'& path': {
			fill: (props) => (props.variant == 'dark' ? '#fff !important' : '#262842 !important'),
		},
	},
	minimizeIcon: {
		position: 'absolute',
		bottom: '-14px',
		right: '-17px',
		zIndex: 9999,
		background: (props) => (props.variant == 'dark' ? '#fff' : 'rgba(38, 40, 66, 0.08)'),
		width: '32px',
		height: '32px',
		opacity: '0',
		transition: 'all 0.5s ease-in-out',
		boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
		border: '1px solid #E0E3EA',
		'& span': {
			marginRight: '2px',
		},
		'&.minimized': {
			transform: 'rotate(180deg)',
		},
	},
	backContainer: {
		display: 'flex',
		gap: '8px',
		alignItems: 'center',
		padding: '10px 12px',
		cursor: 'pointer',
		justifyContent: (props) => (props.isMini ? 'center' : 'flex-start'),
	},
	backIcon: {
		background: (props) => (props.variant == 'dark' ? '#fff' : 'rgba(38, 40, 66, 0.08)'),
		transition: 'transform 0.5s ease-in-out',
		'& span': {
			marginRight: '2px',
		},
		'&.minimized': {
			transform: 'rotate(180deg)',
		},
	},
	backTitle: {
		color: (props) => (props.variant == 'dark' ? '#fff' : '#262842'),
		fontFamily: "'Euclid Circular B', sans-serif",
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '18px,',
	},
	container: {
		borderRight: '1px solid rgba(38, 40, 66, 0.08)',
		display: 'flex',
		width: '248px',
		// width: '100%',
		flexDirection: 'column',
		transition: 'opacity 600ms ease-in-out, width 600ms ease-in-out, top 10ms ease-in-out',
		'&:hover > $logo > $minimizeIcon': {
			opacity: 1,
		},
		'&.mini': {
			width: '64px',
		},
	},
	logo: {
		padding: '14px 12px',
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
	},
	settingsTitle: {
		padding: '10px 12px 12px',
		color: (props) => (props.variant == 'dark' ? '#fff' : '#000000'),
		fontWeight: 500,
		fontSize: '16px',
		lineHeight: '18px',
	},
	link: {
		textDecoration: 'none',
		color: theme.palette.primary[200],
	},
}));

export default useStyles;
