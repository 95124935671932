import { animate } from 'motion';
import { useEffect } from 'react';

type UseWorkspaceAnimations = {
	collapsed: boolean;
	workspaceNameId: string;
	actionBlockId: string;
};

export function useWorkspaceAnimations({ collapsed, workspaceNameId, actionBlockId }: UseWorkspaceAnimations): void {
	useEffect(() => {
		if (workspaceNameId && actionBlockId) {
			const workspaceName = document.getElementById(workspaceNameId);
			const actionBlock = document.getElementById(actionBlockId);

			if (workspaceName && actionBlock) {
				if (collapsed) {
					animate([workspaceName, actionBlock], { opacity: 0 }, { duration: 0.2 }).finished.then(() => {
						animate([workspaceName, actionBlock], { visibility: 'hidden' });
					});
				} else {
					animate([workspaceName, actionBlock], { visibility: 'visible' }).finished.then(() => {
						animate([workspaceName, actionBlock], { opacity: 1 }, { duration: 0.6 });
					});
				}
			}
		}
	}, [collapsed, workspaceNameId, actionBlockId]);
}
