import { TFunction } from 'i18next';
import { useLocation } from 'react-router-dom';

import { HintStep, NotificationCounters, Workspace } from '@dar/api-interfaces';
import { Profile } from '@dartech/griffon-auth';
import { Error, ErrorOutline } from '@material-ui/icons';
import LockIcon from '@material-ui/icons/Lock';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { ReactComponent as IntegrationsIcon } from '@topbar/src/assets/icons/apps_regular.svg';
import { ReactComponent as ArrowSyncIcon } from '@topbar/src/assets/icons/arrow_sync_circle_regular.svg';
import { ReactComponent as BriefcaseFilledIcon } from '@topbar/src/assets/icons/briefcase_filled.svg';
import { ReactComponent as BriefcaseRegularIcon } from '@topbar/src/assets/icons/briefcase_regular.svg';
import { ReactComponent as CalculationFilledIcon } from '@topbar/src/assets/icons/calculator_filled.svg';
import { ReactComponent as CalculationRegularIcon } from '@topbar/src/assets/icons/calculator_regular.svg';
import { ReactComponent as CalendarIcon } from '@topbar/src/assets/icons/calendar_ltr_regular.svg';
import { ReactComponent as CommunityIcon } from '@topbar/src/assets/icons/community_regular.svg';
import { ReactComponent as DashboardIcon } from '@topbar/src/assets/icons/dashboard.svg';
import { ReactComponent as HistogramFilledIcon } from '@topbar/src/assets/icons/data_histogram_filled.svg';
import { ReactComponent as HistogramRegularIcon } from '@topbar/src/assets/icons/data_histogram_regular.svg';
import { ReactComponent as DocflowFilledIcon } from '@topbar/src/assets/icons/docflow_active.svg';
import { ReactComponent as DocflowRegularIcon } from '@topbar/src/assets/icons/docflow_default.svg';
import { ReactComponent as GroupsIcon } from '@topbar/src/assets/icons/groups_settings_icon.svg';
import { ReactComponent as HoldingFilledIcon } from '@topbar/src/assets/icons/holding_filled.svg';
import { ReactComponent as HoldingRegularIcon } from '@topbar/src/assets/icons/holding_regular.svg';
import { ReactComponent as HomeFilledIcon } from '@topbar/src/assets/icons/home_filled.svg';
import { ReactComponent as HomeRegularIcon } from '@topbar/src/assets/icons/home_regular.svg';
import { ReactComponent as IntegrationsFilledIcon } from '@topbar/src/assets/icons/integrations.svg';
import { ReactComponent as DetailIcon } from '@topbar/src/assets/icons/list_detail.svg';
import { ReactComponent as LockClosedIcon } from '@topbar/src/assets/icons/lock_closed_regular.svg';
import { ReactComponent as MathFormulaFilledIcon } from '@topbar/src/assets/icons/math_formula_filled.svg';
import { ReactComponent as MathFormulaRegularIcon } from '@topbar/src/assets/icons/math_formula_regular.svg';
import { ReactComponent as MeetNowFilledIcon } from '@topbar/src/assets/icons/meet_now_filled.svg';
import { ReactComponent as MeetNowRegularIcon } from '@topbar/src/assets/icons/meet_now_regular.svg';
import { ReactComponent as OrganizationRegular } from '@topbar/src/assets/icons/organization_regular.svg';
import { ReactComponent as PeopleTeamFilledIcon } from '@topbar/src/assets/icons/people_team_filled.svg';
import { ReactComponent as PeopleTeamRegularIcon } from '@topbar/src/assets/icons/people_team_regular.svg';
import { ReactComponent as PeopleTeamIcon } from '@topbar/src/assets/icons/people_team_regular.svg';
import { ReactComponent as ProfileConstructorFilledIcon } from '@topbar/src/assets/icons/profile_constructor_filled.svg';
import { ReactComponent as ProfileConstructorRegularIcon } from '@topbar/src/assets/icons/profile_constructor_regular.svg';
import { ReactComponent as SettingsIcon } from '@topbar/src/assets/icons/settings_regular.svg';
import { ReactComponent as StatusRegularIcon } from '@topbar/src/assets/icons/status_regular.svg';
import { ReactComponent as LabelsFilledIcon } from '@topbar/src/assets/icons/tag_filled.svg';
import { ReactComponent as LabelsRegularIcon } from '@topbar/src/assets/icons/tag_regular.svg';
import { MATOMO_KEYS } from '@topbar/src/constants/matomo';
import { PERMISSIONS_ACTIONS, PERMISSIONS_TYPES } from '@topbar/src/constants/permissions';
import { environment } from '@topbar/src/environments/environment';

import {
	BookSearchFilled,
	BookSearchRegular,
	CalendarLtrFilled,
	CalendarLtrRegular,
	PeopleTeamFilled,
	PeopleTeamRegular,
	ReceiptMoneyFilled,
	ReceiptMoneyRegular,
	SettingsFilled,
	SettingsRegular,
} from '../../assets/dms-ui-icons';
import { IData } from './nav-interface';
import { IRoute } from './nav-interface';

export const takeShortName = (value: string) => {
	if (!value?.length) {
		return '';
	}
	const filtered = value.trim().split(' ');

	if (filtered.length === 1) {
		return filtered[0][0].toUpperCase();
	}
	return `${filtered[0][0].toUpperCase()}${filtered[1][0].toUpperCase()}`;
};

function getDocflowRoute(t: TFunction): IRoute {
	return {
		title: t('modules.module_docflow'),
		forCollapse: 'docflow1',
		icon: DocflowRegularIcon,
		filledIcon: DocflowFilledIcon,
		matomo: MATOMO_KEYS.DOC_FLOW,
		collapseRoute: ['/docflow/documents'],
		hint: HintStep.Docflow,
		defaultHintRoute: '/docflow/documents',
		notificationCounter: 0,
		children: [
			{
				title: t('modules.module_docflow_documents'),
				itemId: 'docflow1_documents',
				route: '/docflow/documents',
				notificationCounter: 0,
				matomo: MATOMO_KEYS.DOC_FLOW_DOCUMENTS,
			},
		],
	};
}

export const routes = (
	profile: Profile,
	t: TFunction,
	modules?: { [module: string]: boolean },
	counters?: NotificationCounters
): IRoute[] => {
	const commRoute = {
		title: t('modules.module_com'),
		forCollapse: 'activities1',
		icon: MeetNowRegularIcon,
		filledIcon: MeetNowFilledIcon,
		collapseRoute: ['/productivity/meetings', '/productivity/moms'],
		hint: HintStep.Communications,
		defaultHintRoute: '/productivity/meetings',
		notificationCounter:
			(counters?.meetings || 0) + (counters?.meetingNotes || 0) + (counters?.meetingNotesStatePendingApproval || 0),
		children: [
			{
				title: t('modules.module2_meetings'),
				itemId: 'activities1_meetings',
				route: '/productivity/meetings',
				notificationCounter: counters?.meetings,
				matomo: MATOMO_KEYS.MEETINGS_MEETINGS,
			},
			{
				title: t('modules.module2_meetings_notes'),
				itemId: 'activities1_meetings_notes',
				route: '/productivity/moms',
				notificationCounter: (counters?.meetingNotes || 0) + (counters?.meetingNotesStatePendingApproval || 0),
				matomo: MATOMO_KEYS.MEETINGS_NOTES,
			},
		],
	};

	const currentRegion = environment().region;

	const sidebarRoutes = [
		{
			title: t('modules.module_home'),
			itemId: 'overview',
			icon: HomeRegularIcon,
			route: '/overview',
			filledIcon: HomeFilledIcon,
			hint: HintStep.Home,
			matomo: MATOMO_KEYS.HOME,
		},
		{
			title: t('modules.module_work'),
			forCollapse: 'work1',
			icon: BriefcaseRegularIcon,
			filledIcon: BriefcaseFilledIcon,
			collapseRoute: ['/processes', '/projects', '/plans', '/productivity/tasks', '/budget', '/drive'],
			defaultHintRoute: '/productivity/tasks',
			hint: HintStep.Work,
			notificationCounter:
				(counters?.tasks || 0) + (counters?.processes || 0) + (counters?.projects || 0) + (counters?.budget || 0),
			children: [
				{
					title: t('modules.module2_tasks'),
					itemId: 'work1_tasks',
					route: '/productivity/tasks',
					notificationCounter: counters?.tasks,
					matomo: MATOMO_KEYS.MY_WORK_TASKS,
				},
				{
					title: t('modules.modules4_plans'),
					itemId: 'work1_plans',
					route: '/plans',
					notificationCounter: counters?.plans,
					matomo: MATOMO_KEYS.MY_WORK_PLANS,
				},
				{
					title: t('modules.module4'),
					itemId: 'work1_projects',
					route: '/projects',
					notificationCounter: counters?.projects,
					matomo: MATOMO_KEYS.MY_WORK_PROJECT,
				},
				{
					title: t('modules.module5_processes'),
					itemId: 'work1_processes',
					route: '/processes',
					notificationCounter: counters?.processes,
					matomo: MATOMO_KEYS.MY_WORK_PROCESSES,
				},
				{
					title: t('modules.module5_budget'),
					itemId: 'work1_budget',
					route: '/budget',
					isBeta: true,
					notificationCounter: counters?.budget,
					matomo: MATOMO_KEYS.MY_WORK_BUDGETS,
				},
				{
					title: t('modules.module5_drive'),
					itemId: 'work1_drive',
					route: '/drive',
					isBeta: true,
					matomo: MATOMO_KEYS.MY_WORK_DISK,
				},
			],
		},
		...(currentRegion !== 'in' && currentRegion !== 'en' ? [getDocflowRoute(t), commRoute] : [commRoute]),
		{
			title: t('modules.module_team'),
			forCollapse: 'employees1',
			icon: PeopleTeamRegularIcon,
			filledIcon: PeopleTeamFilledIcon,
			collapseRoute: ['/people'],
			hint: HintStep.Team,
			defaultHintRoute: `/people/profile/${profile.id}/profile`,
			notificationCounter:
				(counters?.profile || 0) + (counters?.timesheet || 0) + (counters?.staffList || 0) + (counters?.structure || 0),
			children: [
				{
					title: t('modules.module3_profile'),
					itemId: 'employees1_profile',
					route: `/people/profile/${profile.id}/profile`,
					notificationCounter: counters?.profile,
				},
				{
					title: t('modules.module3_timesheet'),
					itemId: 'employees1_timesheet',
					route: '/people/timesheets',
					notificationCounter: counters?.timesheet,
					matomo: MATOMO_KEYS.TEAM_TIMESHEET,
				},
				{
					title: t('modules.module3_structure'),
					itemId: 'employees1_structure',
					route: '/people/org-structure',
					notificationCounter: counters?.structure,
					matomo: MATOMO_KEYS.TEAM_ORG_STRUCTURE,
				},
				{
					title: t('modules.comp_and_ben'),
					itemId: 'employees1_hr',
					route: '/people/hr',
					isBeta: true,
					notificationCounter: counters?.hr,
					matomo: MATOMO_KEYS.TEAM_HOLDING_STRUCTURE,
					permissions: [
						{ type: PERMISSIONS_TYPES.CNB_OWN_GROUP, actions: [PERMISSIONS_ACTIONS.READ] },
						{ type: PERMISSIONS_TYPES.CNB_GROUP, actions: [PERMISSIONS_ACTIONS.READ] },
					],
				},
			],
		},
		{
			title: 'Community',
			itemId: 'community',
			icon: CommunityIcon,
			route: '/community',
			filledIcon: CommunityIcon,
		},
	];

	const workspace: Workspace = JSON.parse(localStorage.getItem('workspace') || null);
	if (workspace?.role === 'ADMIN' || workspace?.role === 'OWNER') {
		sidebarRoutes.push({
			title: t('modules.dashboards'),
			itemId: 'dashboard',
			icon: DashboardIcon,
			route: '/dashboard',
			filledIcon: DashboardIcon,
			hint: HintStep.Dashboard,
		});
	}
	if (workspace?.isHolding || workspace?.isSubsidiary) {
		const peopleIndex = sidebarRoutes.findIndex((v) => v.forCollapse === 'employees1');
		sidebarRoutes[peopleIndex]?.children.push({
			title: t('modules.holding_structure'),
			itemId: 'employees1_holding_structure',
			route: '/people/holding-structure',
			isBeta: true,
			notificationCounter: undefined,
		} as any);
	}

	return sidebarRoutes;
};

export const settingsRoutes = (profile: Profile, t: TFunction, modules?: { [module: string]: boolean }): IRoute[] => {
	return [
		{
			title: t('modules.settings_general'),
			itemId: 'general',
			route: '/admin/workspace',
			icon: SettingsRegular,
			filledIcon: SettingsFilled,
		},
		{
			title: t('modules.settings_users_teams'),
			itemId: 'user_teams',
			route: '/admin/users?page=0&limit=25',
			icon: PeopleTeamRegular,
			filledIcon: PeopleTeamFilled,
		},
		...(modules?.integrations_v
			? [
					{
						title: t('integrations.integration_sidebar', { ns: 'admin' }),
						itemId: 'integrations',
						route: '/admin/integrations',
						icon: IntegrationsIcon,
						filledIcon: IntegrationsFilledIcon,
					},
			  ]
			: []),
		{
			title: t('sidebar_settings.permissions'),
			forCollapse: 'permissions',
			defaultHintRoute: '/people/settings',
			icon: LockOutlinedIcon,
			filledIcon: LockIcon,
			collapseRoute: ['/people/settings', '/processes/settings/roles'],
			notificationCounter: 0,
			children: [
				{
					title: t('sidebar_settings.permissions_team'),
					route: '/people/settings',
					icon: PeopleTeamIcon,
					itemId: 'permissions-team',
				},
				{
					title: t('sidebar_settings.permissions_process'),
					route: '/processes/settings/roles',
					icon: ArrowSyncIcon,
					itemId: 'permissions-process',
				},
			],
		},
		{
			title: t('modules.settings_directories'),
			itemId: 'directories',
			route: '/admin/directory',
			icon: BookSearchRegular,
			filledIcon: BookSearchFilled,
		},
		{
			title: t('modules.settings_workcalendar'),
			itemId: 'work_calendar',
			route: '/admin/calendar',
			icon: CalendarLtrRegular,
			filledIcon: CalendarLtrFilled,
		},
		{
			title: t('modules.settings_plans_billing'),
			itemId: 'plans_billing',
			route: '/admin/plans',
			icon: ReceiptMoneyRegular,
			filledIcon: ReceiptMoneyFilled,
		},
		{
			title: t('modules.holding_management'),
			itemId: 'holding_management',
			route: '/admin/holding',
			icon: HoldingRegularIcon,
			filledIcon: HoldingFilledIcon,
			disabled: !modules?.[`general_info_v`],
			disabledTooltip: t('placeholder.lock_placeholder', { plan: t('placeholder.premium_plan') }),
		},
		{
			title: t('modules.profile_constructor', { defaultValue: 'Profile builder' }),
			itemId: 'profile_constructor',
			route: '/admin/profile-constructor',
			icon: ProfileConstructorRegularIcon,
			filledIcon: ProfileConstructorFilledIcon,
			disabled: !modules?.['profile_constructor_info_v'],
			disabledTooltip: t('placeholder.lock_placeholder', { plan: t('placeholder.premium_plan') }),
		},
		{
			title: t('modules.labels', { defaultValue: 'Labels' }),
			itemId: 'labels-page',
			route: '/admin/labels',
			icon: LabelsRegularIcon,
			filledIcon: LabelsFilledIcon,
		},
	];
};

export const settingsMenuConfig = (t: TFunction): IData[] => [
	{
		icon: SettingsIcon,
		route: '/admin',
		title: t('sidebar_settings.workspace_settings'),
		permissions: [],
	},
	{
		icon: LockClosedIcon,
		route: '/',
		title: t('sidebar_settings.permissions'),
		settings: {
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
			transformOrigin: {
				vertical: 'center',
				horizontal: 'left',
			},
		},
		permissions: [],
		children: [
			{
				title: t('sidebar_settings.permissions_team'),
				route: '/people/settings',
				icon: PeopleTeamIcon,
				permissions: [],
			},
			{
				title: t('sidebar_settings.permissions_process'),
				route: '/processes/settings/roles',
				icon: ArrowSyncIcon,
				permissions: [],
			},
		],
	},
	{
		icon: CalendarIcon,
		route: '/admin/calendar',
		title: t('sidebar_settings.work_calendar'),
		permissions: [PERMISSIONS_TYPES.WORKSPACE_CALENDAR],
	},
	{
		title: t('integrations.integration_sidebar', { ns: 'admin' }),
		route: '/community',
		icon: IntegrationsIcon,
	},
];

export const ProjectSettingsRoutes = (profile: Profile, t: TFunction): IRoute[] => {
	const { pathname } = useLocation();
	const paths = pathname.split('/').slice(1);
	const project_id = paths[2];
	return [
		{
			title: 'Details',
			itemId: 'projectDetails',
			icon: DetailIcon,
			filledIcon: DetailIcon,
			route: `/projects/detail/${project_id}/settings/details`,
		},
		// {
		// 	title: 'Goals',
		// 	icon: CommunicationRegularIcon,
		// 	filledIcon: CommunicationRegularIcon,
		// 	itemId: 'projectGoals',
		// 	route: `/projects/detail/${project_id}/settings/goals`
		// },
		{
			title: 'Team & Roles',
			icon: PeopleTeamRegularIcon,
			filledIcon: PeopleTeamRegularIcon,
			itemId: 'projectTeam',
			route: `/projects/detail/${project_id}/settings/teams`,
		},
		{
			title: 'Issue types',
			icon: OrganizationRegular,
			filledIcon: OrganizationRegular,
			itemId: 'projectIssueTypes',
			route: `/projects/detail/${project_id}/settings/issueType`,
		},
		{
			title: 'Issue statuses',
			icon: StatusRegularIcon,
			filledIcon: StatusRegularIcon,
			itemId: 'projectStatuses',
			route: `/projects/detail/${project_id}/settings/statuses`,
		},
	];
};

export const PlanSettingsRoutes = (profile: Profile, t: TFunction): IRoute[] => {
	const { pathname } = useLocation();
	const paths = pathname.split('/').slice(1);
	const project_id = paths[2];
	return [
		{
			title: 'Details',
			itemId: 'projectDetails',
			icon: DetailIcon,
			filledIcon: DetailIcon,
			route: `/plans/detail/${project_id}/settings/details`,
		},
		// {
		// 	title: 'Goals',
		// 	icon: CommunicationRegularIcon,
		// 	filledIcon: CommunicationRegularIcon,
		// 	itemId: 'projectGoals',
		// 	route: `/plans/detail/${project_id}/settings/goals`
		// },
		{
			title: 'Team & Roles',
			icon: PeopleTeamRegularIcon,
			filledIcon: PeopleTeamRegularIcon,
			itemId: 'projectTeam',
			route: `/plans/detail/${project_id}/settings/teams`,
		},
		{
			title: 'Issue types',
			icon: OrganizationRegular,
			filledIcon: OrganizationRegular,
			itemId: 'projectIssueTypes',
			route: `/plans/detail/${project_id}/settings/issueType`,
		},
		{
			title: 'Issue statuses',
			icon: StatusRegularIcon,
			filledIcon: StatusRegularIcon,
			itemId: 'projectStatuses',
			route: `/plans/detail/${project_id}/settings/statuses`,
		},
	];
};

export const cnbSettingsRoutes = (t: TFunction, groupId: string) => {
	return () => [
		{
			title: t('modules.settings_general'),
			itemId: 'cnb_general',
			icon: ErrorOutline,
			filledIcon: Error,
			route: `/people/cnb-settings/general?groupId=${groupId}`,
		},
		{
			title: t('modules.users_groups', { defaultValue: 'Users and Groups' }),
			itemId: 'cnb_users_groups',
			icon: PeopleTeamRegularIcon,
			filledIcon: PeopleTeamFilledIcon,
			route: `/people/cnb-settings/users-groups?groupId=${groupId}`,
		},
		{
			title: t('modules.parameters', { defaultValue: 'Parameters' }),
			itemId: 'cnb_parameters',
			icon: HistogramRegularIcon,
			filledIcon: HistogramFilledIcon,
			route: `/people/cnb-settings/parameters?groupId=${groupId}`,
		},
		{
			title: t('modules.formula_builder', { defaultValue: 'Formula builder' }),
			itemId: 'cnb_formula_builder',
			icon: MathFormulaRegularIcon,
			filledIcon: MathFormulaFilledIcon,
			route: `/people/cnb-settings/formula-builder?groupId=${groupId}`,
		},
		{
			title: t('modules.calculation', { defaultValue: 'Calculation' }),
			itemId: 'cnb_calculation',
			icon: CalculationRegularIcon,
			filledIcon: CalculationFilledIcon,
			route: `/people/cnb-settings/calculation?groupId=${groupId}`,
		},
	];
};

export const ProcessesSettingsRoutes = (t: (v: string) => string): IRoute[] => {
	return [
		{
			title: t('processes_settings.groups'),
			itemId: 'groupsSettings',
			icon: GroupsIcon,
			filledIcon: GroupsIcon,
			route: `/processes/settings/groups`,
		},
	];
};

export const CommunityRoutes = (t: (v: string) => string): IRoute[] => {
	return [
		{
			icon: CommunityIcon,
			route: '/community',
			title: 'Community',
			permissions: [],
		},
	];
};
