import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { UserLanguage } from '@dar/api-interfaces';
import { Box, Menu, Typography, makeStyles, MenuItem, } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FlagIcon from '@material-ui/icons/FlagOutlined';
import { ReactComponent as Language } from '@topbar/src/components/sidebar/svg/language.svg';
import { ReactComponent as Logout } from '@topbar/src/components/sidebar/svg/logout.svg';
import { ReactComponent as Profile } from '@topbar/src/components/sidebar/svg/profile.svg';
import { environment } from '@topbar/src/environments/environment';
import { getLanguages, setLanguage } from '@topbar/src/services/language';
import { useAuth } from '@topbar/src/topbar/providers/auth-provider';
import { TYPE_ENUM, useConfirm } from '@topbar/src/utils/useConfirm';

import { useAnchorEl } from '../../../hooks/use-anchor-el';
import { sidebarTheme } from '../../../sidebar-theme';
import { LanguageMenu } from '../language-menu/language-menu';
import { RegionMenu } from '../region-menu/region-menu';
import { StyledProfileMenu } from './profile-menu-styles';

const useOwnStyles = makeStyles({
	paper: {
		transform: 'translate(3%, 0px) !important',
		boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
		borderRadius: '10px',
		border: '1px solid #2628421F',
		width: '320px',
		height: '145px',
		padding: `${sidebarTheme.spacing['positive-100']} 0`,
		overflowY: 'hidden',
	},
});

const ProfileMenu = ({ anchorEl, onClose, open, profile }) => {
	const { onConfirm } = useConfirm();
	const ownClasses = useOwnStyles();
	const history = useHistory();
	const { t, i18n } = useTranslation();
	const { logout } = useAuth();

	const [langs, setLangs] = useState([]);

	const { 
		anchorEl: languageAnchorEl,
		onModalClose: onLanguageModalClose,
		onModalOpen: onLanguageModalOpen,
	} = useAnchorEl();
	const {
		anchorEl: regionAnchorEl,
		onModalClose: onRegionModalClose,
		onModalOpen: onRegionModalOpen,
	} = useAnchorEl();

	const { mutate: changeLanguage } = useMutation((payload: UserLanguage) => setLanguage(payload), {
		onSuccess: (payload: UserLanguage) => {
			i18n.changeLanguage(payload?.language?.toLowerCase());
			window.location.reload();
		},
	});

	const handleLanguageSelect = useCallback(
		(lang: string) => {
			i18n.changeLanguage(lang);
			const data: UserLanguage = {
				userId: profile?.id,
				language: lang.toUpperCase(),
			};
			changeLanguage(data);
			onLanguageModalClose();
		},
		[onLanguageModalClose, profile, changeLanguage, i18n]
	);

	const handleRegionMenuClose = useCallback(() => {
		onRegionModalClose();
		onClose();
	}, [onClose, onRegionModalClose]);

	useQuery({
		queryFn: getLanguages,
		onSuccess: (data) => {
			setLangs(data);
		},
	});

	const onProfileHandler = () => {
		onConfirm((isVideoConference: boolean) => {
			const route = `/people/profile/${profile?.id}/profile`;
			if (isVideoConference) {
				window.open(route);
			} else {
				history.push(route);
			}
			onClose();
		});
	};

	return (
		<Menu
			id='menu-appbar'
			anchorEl={anchorEl}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			keepMounted
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={open}
			onClose={onClose}
			MenuListProps={{
				style: { width: '100%', padding: '0px' },
			}}
			classes={ownClasses}
		>
			<StyledProfileMenu.MenuItem onClick={onProfileHandler}>
				<Profile />
				<Typography variant='inherit'>{t('menu.field2')}</Typography>
			</StyledProfileMenu.MenuItem>

			<StyledProfileMenu.MenuItem
				onClick={onLanguageModalOpen}
				style={{ justifyContent: 'space-between' }}
			>
				<Box display='flex' flexDirection='row' alignItems='center'>
					<Language />
					<Typography>{t('menu.field3')}</Typography>
				</Box>
			</StyledProfileMenu.MenuItem>
			<LanguageMenu anchorEl={languageAnchorEl} onClose={onLanguageModalClose} onSelect={handleLanguageSelect} langs={langs} />
			{(environment().envName === 'development' || environment().envName === 'local' || environment().envName === 'qa') && false && (
				<StyledProfileMenu.MenuItem onClick={onRegionModalOpen} style={{ justifyContent: 'space-between' }}>
					<Box display='flex' flexDirection='row' alignItems='center' style={{ gap: '8px' }}>
						<FlagIcon style={{ fontSize: 20, color: '#6D7C8B' }} />
						<Typography>Region(dev only)</Typography>
					</Box>
					<Box>
						<ArrowForwardIosIcon style={{ width: '60%', color: '#6D7C8B' }} />
					</Box>
				</StyledProfileMenu.MenuItem>
			)}
			<RegionMenu anchorEl={regionAnchorEl} onClose={handleRegionMenuClose} />
			<StyledProfileMenu.Divider />
			<StyledProfileMenu.MenuItem onClick={() => onConfirm(() => logout(), TYPE_ENUM.LOG_OUT)}>
				<Logout /> <Typography variant='inherit'>{t('menu.field4')}</Typography>
			</StyledProfileMenu.MenuItem>
		</Menu>
	);
};

export { ProfileMenu };
