import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import useStyles from '../style';

interface Props {
	isMini: boolean;
	variant: 'light' | 'dark';
	backPath?: string;
	backText?: string;
}

const Back = ({ isMini, variant, backPath, backText }: Props) => {
	const classes = useStyles({ isMini, variant });
	const history = useHistory();

	const backHandler = () => {
		history.push(backPath);
	};

	return (
		<Box className={classes.backContainer} onClick={backHandler}>
			<IconButton className={`${classes.backIcon} minimized}`}>
				<ArrowBackIcon fontSize='small' />
			</IconButton>
			{!isMini && <Typography className={classes.backTitle}>{backText}</Typography>}
		</Box>
	);
};

export { Back };
