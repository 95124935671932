export const STYLES_PREFIX = 'topbar';

export const COOKIE_SETTINGS_KEY = 'cookie-settings';

export interface IChatControlContext {
	isDrawerOpen: boolean;
	toggleDrawer: () => void;
	isFullScreen: boolean;
	toggleFullScreen: () => void;
}

export interface IProviderProps {
	value: IChatControlContext;
}
