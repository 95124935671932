import { NotificationPayloadUnion } from './notification-payload.types';

export enum EntityTypes {
	REQUEST = 'REQUEST',
	TASK = 'TASK',
	MOM = 'MOM',
	TIME_SHEET = 'TIME_SHEET',
	ORG_STRUCTURE = 'ORG_STRUCTURE',
	PERSON = 'PERSON',
}

export enum NotificationNames {
	TIMESHEETS_FOR_APPROVAL = 'TIMESHEETS_FOR_APPROVAL',
	MY_TIMESHEETS = 'MY_TIMESHEETS',
}

export interface INotificationData {
	notificationId: string;
	title: string;
	text: string;
	date: Date;
	type: string;
	url: string;
	status?: string;
	status_color?: string;
	status_previous?: string;
	status_previous_color?: string;
	read: boolean;
	icon: EntityTypes;
	id: number;
	i18n: string;
	setData(
		notificationId: string,
		title: string,
		body: string,
		date: string,
		read: boolean,
		url: string,
		entityType: EntityTypes
	): this;
	setPayload(payload: NotificationPayloadUnion): this;
	setRead(read: boolean): void;
	isValid(): this;
}

export interface NotificationMessage {
	notificationId: string;
	title: string;
	body: string;
	type: EntityTypes;
	dateTime: string;
	read: boolean;
	url: string;
	payload: NotificationPayloadUnion;
}

export interface NotificationCounters {
	myWork?: number;
	tasks?: number;
	processes?: number;
	projects?: number;
	plans?: number;
	budget?: number;
	communications?: number;
	meetings?: number;
	meetingNotes?: number;
	meetingNotesStatePendingApproval?: number;
	team?: number;
	profile?: number;
	staffList?: number;
	ORG_STRUCTURE?: number;
	hr?: number;
	lms?: number;
	CHAT?: number;
	UNREAD?: number;
	BOT?: number;
	MY_TIMESHEETS?: number;
}

export interface TotalNotificationCounters {
	total: number;
	workspaces: {
		holdingId: string;
		companyId: string;
		userId: string;
		total: number;
	}[];
}
