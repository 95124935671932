import { ModuleSetting, updatePinnedMenuItems } from '@topbar/src/services/sidebar';

type PutModuleSettings = {
	modulesSettings: readonly ModuleSetting[];
	onSuccess: () => void;
};

export async function putModuleSettings({ modulesSettings, onSuccess }: PutModuleSettings) {
	try {
		await updatePinnedMenuItems(modulesSettings);

		onSuccess();
	} catch {
		// TODO: Add error handling
	}
}
