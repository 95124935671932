import { useLocation } from 'react-router-dom';

import { Profile } from '@dartech/griffon-auth';
import { ReactComponent as ArrowSyncIcon } from '@topbar/src/assets/icons/arrow_sync_circle_regular.svg';
import { ReactComponent as CalendarIcon } from '@topbar/src/assets/icons/calendar_ltr_regular.svg';
import { ReactComponent as CommunicationRegularIcon } from '@topbar/src/assets/icons/communication_regular.svg';
import { ReactComponent as DetailIcon } from '@topbar/src/assets/icons/list_detail.svg';
import { ReactComponent as LockClosedIcon } from '@topbar/src/assets/icons/lock_closed_regular.svg';
import { ReactComponent as OrganizationRegular } from '@topbar/src/assets/icons/organization_regular.svg';
import { ReactComponent as PeopleTeamRegularIcon } from '@topbar/src/assets/icons/people_team_regular.svg';
import { ReactComponent as PeopleTeamIcon } from '@topbar/src/assets/icons/people_team_regular.svg';
import { ReactComponent as SettingsIcon } from '@topbar/src/assets/icons/settings_regular.svg';
import { PERMISSIONS_TYPES } from '@topbar/src/constants/permissions';

import { IRoute } from './nav-interface';
import { IData } from './nav-interface';

export const takeShortName = (value: string) => {
	if (!value?.length) {
		return '';
	}
	const filtered = value
		.trim()
		.split(' ')
		.filter((item) => item != 'ТОО');
	if (filtered.length == 1) {
		return filtered[0][0].toUpperCase();
	}

	return `${filtered[0][0].toUpperCase()}${filtered[1][0].toUpperCase()}`;
};

export const settingsMenuConfig: IData[] = [
	{
		icon: SettingsIcon,
		route: '/admin',
		title: 'Workspace settings',
		permissions: [],
	},
	{
		icon: LockClosedIcon,
		route: '/',
		title: 'Permissions',
		settings: {
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
			transformOrigin: {
				vertical: 'center',
				horizontal: 'left',
			},
		},
		permissions: [],
		children: [
			{
				title: 'Team',
				route: '/people/settings',
				icon: PeopleTeamIcon,
				permissions: [],
			},
			{
				title: 'Processes',
				route: '/processes/settings/roles',
				icon: ArrowSyncIcon,
				permissions: [],
			},
			// {
			// 	title: 'Projects',
			// 	route: '/',
			// 	icon: LayerIcon,
			// },
		],
	},
	{
		icon: CalendarIcon,
		route: '/admin/calendar',
		title: 'Work calendar',
		permissions: [PERMISSIONS_TYPES.WORKSPACE_CALENDAR],
	},
];

export const ProjectSettingsRoutes = (profile: Profile, t: (v: string) => string): IRoute[] => {
	const { pathname } = useLocation();
	const paths = pathname.split('/').slice(1);
	const project_id = paths[2];
	return [
		{
			title: t('projectNav.details'),
			itemId: 'projectDetails',
			icon: DetailIcon,
			filledIcon: DetailIcon,
			route: `/projects/detail/${project_id}/settings/details`,
		},
		{
			title: t('projectNav.goals'),
			icon: CommunicationRegularIcon,
			filledIcon: CommunicationRegularIcon,
			itemId: 'projectGoals',
			route: `/projects/detail/${project_id}/settings/goals`,
		},
		{
			title: t('projectNav.team_&_roles'),
			icon: PeopleTeamRegularIcon,
			filledIcon: PeopleTeamRegularIcon,
			itemId: 'projectTeam',
			route: `/projects/detail/${project_id}/settings/teams`,
		},
		{
			title: t('projectNav.issue_types'),
			icon: OrganizationRegular,
			filledIcon: OrganizationRegular,
			itemId: 'projectIssueTypes',
			route: `/projects/detail/${project_id}/settings/issueType`,
		},
		// {
		// 	title: t('projectNav.issue_statuses'),
		// 	icon: StatusRegularIcon,
		// 	filledIcon: StatusRegularIcon,
		// 	itemId: 'projectStatuses',
		// 	route: `/projects/detail/${project_id}/settings/statuses`,
		// },
	];
};

export const PlanSettingsRoutes = (profile: Profile, t: (v: string) => string): IRoute[] => {
	const { pathname } = useLocation();
	const paths = pathname.split('/').slice(1);
	const project_id = paths[2];
	return [
		{
			title: t('projectNav.details'),
			itemId: 'projectDetails',
			icon: DetailIcon,
			filledIcon: DetailIcon,
			route: `/plans/detail/${project_id}/settings/details`,
		},
		{
			title: t('projectNav.team_&_roles'),
			icon: PeopleTeamRegularIcon,
			filledIcon: PeopleTeamRegularIcon,
			itemId: 'projectTeam',
			route: `/plans/detail/${project_id}/settings/teams`,
		},
		{
			title: t('projectNav.issue_types'),
			icon: OrganizationRegular,
			filledIcon: OrganizationRegular,
			itemId: 'projectIssueTypes',
			route: `/plans/detail/${project_id}/settings/issueType`,
		},
		// {
		// 	title: t('projectNav.issue_statuses'),
		// 	icon: StatusRegularIcon,
		// 	filledIcon: StatusRegularIcon,
		// 	itemId: 'projectStatuses',
		// 	route: `/plans/detail/${project_id}/settings/statuses`,
		// },
	];
};
