import { Environment } from '@dar/api-interfaces';

// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = (t?: (v: string) => string): Environment => ({
	production: true,
	envName: 'production',
	authBffRoot: 'https://dms-layout.dar.io',
	griffonApiRoot: 'https://account.5q.kz/api/v1',
	griffonSocialAuthUrl: 'https://account.5q.kz/api/social',
	griffonClientId: '4ef0a145-a007-4dd3-a6de-d60f20b934e8',
	griffonClientSecret: 'qVOzQncHYVEJHVrbH-4OJoZVrHOGyS-WKiiB3BiwIwtJzlUBdLZjCJbNgNi15G4q',
	griffonProfilePage: 'https://account.5q.kz/profile',
	griffonRedirectUrl: 'http://dms-layout.dar.io/api/auth/griffon/callback',
	notificationWebsocketUrl: 'wss://dms.dar.io/api/v1/dms-web-socket/notification',
	dmsBase: 'https://dms.dar.io',
	dmsBaseApi: 'https://dms.dar.io/api/v1',
	communityApi: 'https://dms.dar.io/api/v1/community/main-api',
	projectBaseApi: 'https://dms.dar.io/api/v1/project-api',
	dmsWorkchatUrl: 'https://workchat.dar.io',
	dmsAppleStoreUrl: 'https://apps.apple.com/kz/app/5qit/id1508293776',
	dmsGoogleStoreUrl: 'https://play.google.com/store/apps/details?id=io.dar.qit.kz',
	commsApiUrl: 'https://comms-dms.dar.io/api',
	authDomain: '.dar.io',
	dfAgentID: 'ed039b0f-6b97-4145-94f8-441c5ccb4734',
	routes: [
		{ to: '/overview', label: t?.('modules.modules1') },
		{ to: '/productivity', label: t?.('modules.modules2') },
		{ to: '/processes', label: t?.('modules.modules3') },
		{ to: '/people', label: t?.('modules.modules4') },
		{ to: '/projects', label: t?.('modules.modules5') },
		{ to: '/budget', label: 'Operations' },
		{ to: '/docflow', label: 'Doc Flow' },
	],
	hcmsApiRoot: 'https://dms.dar.io/api/v1/hcms',
	dmsSettingsApiUrl: 'https://dms-settings.dar.io/api/v1',
	layoutApi: 'https://dms-layout.dar.io/api',
	hostBrandMapping: {
		'5qbe': '5qbe.dar.io',
		darlean: 'app.darlean.kz',
		dms: 'dms.dar.io',
		localhost: 'localhost:9000',
	},
	bucketId: 'bb4fc077-3c81-4419-a4d8-c2466aa12b42',
	licensedCompanies: [
		'c721c14b-326f-4952-ad0e-21dcad40f018',
		'bb4fc077-3c81-4419-a4d8-c2466aa12b42',
		'125c4914-9c57-48f7-9433-192afd8336a3',
		'5e09fa6d-ba99-47c0-931f-96ab4ee46564',
		'0cbcb597-fd2a-4689-b92f-8d3306a04d21',
		'0f251d64-c89e-4e4d-a536-e39ddeaa7256',
		'74dde3db-e045-460c-9ebe-eed0a04854c1',
		'e5de80b2-3166-42ba-ab13-8648c0ac4d60',
		'b1166cbc-733c-4a60-b04f-f5d3287d0662',
		'c8c0df95-a0ed-4985-a384-a15a278b2499',
		'70bb7484-9f0d-4109-b61b-596b136d6434',
		'f23db8d5-0f52-4ff5-9eed-d93a7d38aa67',
		'fd0f4ee5-ac51-4f5d-a0b0-2d046e9371e0',
		'b1807ab9-2313-4bfa-8819-94d518c3aab1',
		'8a1ec7fb-89cb-4134-92f6-e61fda80757b',
		'17ea4ae8-efe2-4350-ae9e-f5475033ab3c',
		'a5b4f5de-57c4-4d71-bd75-caf9c0131b5e',
		'755b7bfb-ff67-4a81-a48f-89c054f188b7',
		'64bd18d8-c9ba-481f-8b06-6cdd5198652f',
	],
	darHoldingId: 'bb4fc077-3c81-4419-a4d8-c2466aa12b42',
	supportedLangs: ['kz', 'en', 'ru', 'id', 'de'],
	region: 'kz',
	defaultLanguage: 'en',
	hcmsBffApi: 'https://dms-hcms.dar.io/api',
	merchantId: '4c6b120a-5341-406f-ba78-09349b4446f6',
	roles: ['MEMBER', 'OWNER', 'ADMIN', 'GUEST'],
	landingUrl: 'https://darlean.com',
	defaultCountryCode: 'kz',
	conference: 'https://dms.dar.io',
});
