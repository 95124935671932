import * as React from 'react';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgCalendarLtrFilled({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				d='M17.5 7.083v7.709a2.708 2.708 0 01-2.708 2.708H5.208A2.708 2.708 0 012.5 14.792V7.083h15zM6.042 12.5a1.041 1.041 0 100 2.083 1.041 1.041 0 000-2.083zm3.958 0a1.041 1.041 0 100 2.083 1.041 1.041 0 000-2.083zM6.042 8.75a1.042 1.042 0 100 2.083 1.042 1.042 0 000-2.083zm3.958 0a1.042 1.042 0 100 2.083 1.042 1.042 0 000-2.083zm3.958 0a1.042 1.042 0 100 2.083 1.042 1.042 0 000-2.083zm.834-6.25A2.708 2.708 0 0117.5 5.208v.625h-15v-.625A2.708 2.708 0 015.208 2.5h9.584z'
				fill='#262842'
			/>
		</svg>
	);
}

const MemoSvgCalendarLtrFilled = React.memo(SvgCalendarLtrFilled);
export default MemoSvgCalendarLtrFilled;
