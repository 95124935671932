import React, { PropsWithChildren, ReactElement, useEffect, useMemo, useState } from 'react';

import { ChatContext } from '../../contexts/chat-context';
import { useChat } from '../../hooks/useChat';
import { IChatControlContext, IProviderProps } from '../../shared/constants';

type AibotData = {
	AIBotChat: React.FC<{}>;
	ChatControlContextProvider: React.FC<IProviderProps>;
	useChatControl: () => IChatControlContext;
};

type ChatBotProviderProps = {};

function isAibotData(aibotData: any): aibotData is AibotData {
	return 'AIBotChat' in aibotData && 'ChatControlContextProvider' in aibotData && 'useChatControl' in aibotData;
}

export function ChatBotProviderWrapper({ children }: PropsWithChildren<ChatBotProviderProps>): ReactElement {
	const [aibotData, setAibotData] = useState<AibotData | {}>({});

	useEffect(() => {
		async function getAibotModule() {
			try {
				const test = await (window as any).System.import('@dar-dms/aibot');
				return test;
			} catch {
				return {};
			}
		}

		getAibotModule()
			.then(setAibotData)
			.catch(() => {
				setAibotData({});
			});
	}, []);

	if (isAibotData(aibotData)) {
		return <ChatBotProvider aibotData={aibotData}>{children}</ChatBotProvider>;
	}

	return children as ReactElement;
}

function ChatBotProvider({ aibotData, children }: PropsWithChildren<{ aibotData: AibotData }>): ReactElement {
	const { AIBotChat, ChatControlContextProvider, useChatControl } = aibotData;
	const { chatControl, onChatBotClick, toggleChat } = useChat(useChatControl);

	return (
		<ChatContext.Provider value={{ onChatBotClick, toggleChat }}>
			<ChatControlContextProvider value={chatControl}>
				{children}
				<AIBotChat />
			</ChatControlContextProvider>
		</ChatContext.Provider>
	);
}
