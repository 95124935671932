import styled from '@emotion/styled';
import { Box } from '@material-ui/core';

import { Typography } from '../../../components/shared/typography/typography';

export const Wrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	& svg {
		min-width: 20px;
		min-height: 20px;
	}
`;


const Title = styled(Typography)`
	color: #262842;
	margin-left: ${(props) => props.theme.spacing['positive-125']};
`;

export const StyledMenuItemIconContainer = {
  Title,
  Wrapper,
};