import styled from '@emotion/styled';
import { Button, Menu as MuiMenu } from '@mui/material';
import { ReactComponent as LogoIconBase } from '@topbar/src/assets/auth/logo.svg';

const Menu = styled(MuiMenu)({
	'& .MuiPaper-root': {
		minWidth: '150px',
	},
});

const Header = styled('header')<{ showLogo: boolean }>(({ showLogo }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	padding: '24px',
	gap: '12px',
	justifyContent: showLogo ? 'space-between' : 'flex-end',
	alignItems: 'center',
	'@media (max-width: 960px)': {
		padding: '16px',
	},
	'& .buttons': {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		'@media (max-width: 960px)': {
			margin: 'unset',
		},

		'& button': {
			height: '40px',
		},
	},
}));

const LogoIcon = styled(LogoIconBase)<{ show?: boolean }>(({ show }) => ({
	display: show ? 'inline-block' : 'none',
	'@media (max-width: 960px)': {
		margin: '0px auto',
	},
}));

const LangButton = styled(Button)({
	textTransform: 'uppercase',
	marginRight: '12px',
});

export default { Menu, Header, LogoIcon, LangButton };
