export enum PERMISSIONS_TYPES {
	ORG_STRUCTURE = 'ORG_STRUCTURE',
	PERMISSIONS = 'PERMISSIONS',
	ALL_PROFILES = 'ALL_PROFILES',
	USER_AND_TEAMS = 'USER_AND_TEAMS',
	EMPLOYEE_PROFILE = 'EMPLOYEE_PROFILE',
	STAFF_LIST = 'STAFF_LIST',
	TIMESHEET_ADMINISTRATION = 'TIMESHEET_ADMINISTRATION',
	DIRECTORIES = 'DIRECTORIES',
	COMPANY_PROFILE = 'COMPANY_PROFILE',
	WORKSPACE_CALENDAR = 'WORKSPACE_CALENDAR',
	HOLDING_ADMINISTRATION = 'HOLDING_ADMINISTRATION',
	CNB_GROUP = 'CNB_GROUP',
	CNB_OWN_GROUP = 'CNB_OWN_GROUP',
	MARKETPLACE_PROFILE = 'MARKETPLACE_PROFILE',
	DASHBOARD = 'DASHBOARD',
}

export enum PERMISSIONS_ACTIONS {
	WRITE = 'WRITE',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE',
	READ = 'READ',
}
