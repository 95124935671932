import * as React from 'react';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgSettingsRegular({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				d='M10.01 1.875c.612.007 1.22.077 1.818.21a.625.625 0 01.485.542l.142 1.272a1.153 1.153 0 001.606.93l1.167-.512a.625.625 0 01.709.145 8.16 8.16 0 011.836 3.16.625.625 0 01-.226.687l-1.035.764a1.15 1.15 0 000 1.854l1.036.763a.625.625 0 01.227.688 8.163 8.163 0 01-1.837 3.16.625.625 0 01-.706.146l-1.173-.514a1.15 1.15 0 00-1.605.928l-.14 1.272a.625.625 0 01-.477.54 7.934 7.934 0 01-3.672 0 .625.625 0 01-.477-.54l-.14-1.27a1.152 1.152 0 00-1.605-.925l-1.171.513a.625.625 0 01-.708-.146 8.166 8.166 0 01-1.837-3.163.625.625 0 01.227-.688l1.036-.764a1.15 1.15 0 000-1.854L2.454 8.31a.625.625 0 01-.226-.689 8.16 8.16 0 011.837-3.16.625.625 0 01.708-.144l1.167.512a1.156 1.156 0 001.608-.932l.142-1.271a.625.625 0 01.486-.542 8.94 8.94 0 011.834-.21zm0 1.25a7.613 7.613 0 00-1.128.098l-.091.814a2.405 2.405 0 01-3.354 1.938l-.748-.328a6.91 6.91 0 00-1.123 1.93l.665.49a2.4 2.4 0 010 3.869l-.666.49a6.92 6.92 0 001.123 1.935l.755-.331a2.402 2.402 0 013.347 1.932l.092.82c.74.125 1.498.125 2.239 0l.091-.82a2.4 2.4 0 013.349-1.935l.754.33a6.915 6.915 0 001.123-1.932l-.665-.49a2.401 2.401 0 010-3.87l.663-.488a6.909 6.909 0 00-1.123-1.931l-.747.327a2.403 2.403 0 01-3.353-1.936l-.091-.814a7.493 7.493 0 00-1.111-.097l-.001-.001zM10 6.875a3.125 3.125 0 110 6.25 3.125 3.125 0 010-6.25zm0 1.25a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75z'
				fill='#262842'
			/>
		</svg>
	);
}

const MemoSvgSettingsRegular = React.memo(SvgSettingsRegular);
export default MemoSvgSettingsRegular;
