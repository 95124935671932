import React, { FC, useEffect, useMemo, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { useTranslation } from 'react-i18next';

import { SideMenu } from '@dartech/dms-ui';
import { Profile as IProfile } from '@dartech/griffon-auth';
import { Box, Divider, IconButton, Typography } from '@material-ui/core';
import { useAuth } from '@topbar/src/topbar/providers/auth-provider';
import { useBrand } from '@topbar/src/topbar/providers/brand-provider';

import { Back, Content, Settings } from './internal';
import { IRoute } from './nav-interface';
import useStyles from './style';

interface Props {
	backPath?: string;
	backText?: string;
	routes: (profile: IProfile, t: (v: string) => string) => IRoute[];
	variant: 'light' | 'dark';
}

const Nav: FC<Props> = ({ routes, variant, backPath, backText }) => {
	const { profile } = useAuth();
	const { isNavMini, setisNavMini } = useBrand();
	const [_isNavMini, _setisNavMini] = useState();
	const classes = useStyles({ isMini: isNavMini, variant });
	const { t } = useTranslation();

	useEffect(() => {
		setisNavMini((prev) => {
			_setisNavMini(prev);
			return false;
		});
	}, []);

	useEffect(() => {
		return () => setisNavMini((prev) => _isNavMini);
	}, [_isNavMini]);

	return (
		<Div100vh className={`sticky ${classes.container} ${isNavMini ? 'mini' : ''} `}>
			<SideMenu isMini={isNavMini} variant={variant}>
				<Divider className={classes.divider} />
				{backPath && (
					<>
						<Back isMini={isNavMini} variant={variant} backPath={backPath} backText={backText} />
						{!isNavMini && <Typography className={classes.settingsTitle}>{t('budgetNav.title')}</Typography>}
						{!isNavMini && <Typography className={classes.settingsSubTitle}>{t('budgetNav.subTitle')}</Typography>}
					</>
				)}
				<Content isMini={isNavMini} profile={profile} routes={routes} variant={variant} />
				{variant == 'dark' && (
					<>
						<Divider className={classes.divider} />
						<Settings isMini={isNavMini} variant={variant} />
						<Divider className={classes.divider} />
						{/* <ViewButton isMini={isNavMini} variant={variant} /> */}
					</>
				)}
			</SideMenu>
		</Div100vh>
	);
};

export { Nav };
