import { Theme, makeStyles } from '@material-ui/core';

type Props = {
	isMini: boolean;
	variant: 'dark' | 'light';
	height?: number;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
	workspaceWrapper: {
		width: 'calc(100% - 24px)',
		minHeight: '40px',
		padding: (props) => (props.isMini ? '4px' : '12px'),
		margin: '12px',
		cursor: 'pointer',
		background: (props) => (props.variant === 'light' ? '#F7F8FC' : 'rgba(255, 255, 255, 0.12)'),
		borderRadius: '10px',
	},
	workspaceCountersWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: (props) => (props.isMini ? 'center' : 'space-between'),
		paddingTop: (props) => (props.isMini ? '2px' : '12px'),
		borderTop: (props) =>
			props.variant === 'light' ? '1px solid rgba(38, 40, 66, 0.12)' : '1px solid rgba(255, 255, 255, 0.16)',
		overflow: 'hidden',
	},
	workspaceCounters: {
		display: 'flex',
		alignItems: 'center',
		gap: '6px',
	},
	workspaceLabel: {
		margin: (props) => (props.isMini ? '0' : '0 0 8px'),
		maxHeight: (props) => (props.isMini ? '0px' : '32px'),
		fontSize: '12px',
		fontWeight: 500,
		lineHeight: '16px',
		transition: (props) => (props.isMini ? 'none' : 'max-height 1s ease-in, opacity 1s ease-in'),
		opacity: (props) => (props.isMini ? '0' : '1'),
	},
	logoWorkspace: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		width: '32px',
	},
	logoWorkspaceSmall: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		width: '24px',
		height: 24,
		objectFit: 'contain',
	},
	menuItem: {
		cursor: 'default',
		'&:hover': {
			background: '#fff',
		},
	},
	profileWrapper: {
		cursor: 'pointer',
		'&.opened': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		},
	},
	item: {
		display: 'flex',
		alignItems: 'center',
		padding: '15px 16px',
		gap: '8px',
		height: '50px',
		'&:hover': {
			background: 'rgba(38, 40, 66, 0.04)',
		},
	},
	workspaceMenuContainer: {
		padding: '15px 16px',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	workspaceButton: {
		marginLeft: '10px',
		fontSize: '14px',
		lineHeight: '18px',
		fontWeight: 400,
	},
	globe: {
		'& > path': {
			fill: '#007994',
		},
	},
	workspaceContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: (props) => (props.isMini ? '2px' : '12px'),
		justifyContent: (props) => (props.isMini ? 'center' : 'start'),
		'&.no-margin': {
			marginBottom: '0px',
		},
	},
	workspaceShortName: {
		backgroundColor: '#497CF6',
		width: '32px',
		height: '32px',
		fontSize: '14px',
		border: '1px solid rgba(255, 255, 255, 0.6)',
		borderRadius: '6px',
	},
	workspaceFullName: {
		fontSize: '14px',
		width: 170,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	profileContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: (props) => (props.isMini ? 'center' : 'flex-start'),
	},
	role: {
		fontSize: '14px',
		color: (props) => (props.variant == 'dark' ? '#fff' : '#262842'),
	},
	email: {
		fontSize: '14px',
		color: (props) => (props.variant == 'dark' ? '#F7F8FC' : '#262842'),
		opacity: '0.6',
	},
	viewButtonContainer: {
		padding: '12px',
		display: 'flex',
	},
	viewMiniButton: {
		backgroundColor: '#2DB77B',
		border: 'none',
		margin: 'auto',
		'& > span > svg > path': {
			fill: '#fff',
		},
	},
	viewButton: {
		backgroundColor: '#2DB77B',
		border: 'none',
		minHeight: '40px',
		borderRadius: '10px',
		'& > span': {
			fontWeight: 500,
		},
	},
	divider: {
		backgroundColor: (props) => (props.variant == 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'D9D9D9'),
	},
	settingsButtons: {
		display: 'flex',
		gap: '10px',
		flexDirection: (props) => (props.isMini ? 'column' : 'row'),
	},
	icon: {
		border: 'none',
		borderRadius: '8px !important',
		backgroundColor: (props) => (props.variant == 'dark' ? 'rgba(255, 255, 255, 0.08)' : '#ffffff'),
		'&:hover': {
			backgroundColor: 'rgba(38, 40, 66, 0.12)',
		},
		'& span svg path': {
			fill: (props) => (props.variant == 'dark' ? '#fff !important' : '#262842 !important'),
		},
	},
	avatar: {
		width: '32px',
		height: '32px',
		margin: '0px !important',
	},
	menuIcon: {
		'& path': {
			fill: (props) => (props.variant == 'dark' ? '#fff !important' : ''),
		},
	},
	minimizeIcon: {
		position: 'absolute',
		bottom: '-14px',
		right: '-17px',
		zIndex: 4999,
		background: (props) => (props.variant == 'dark' ? '#fff' : 'rgba(38, 40, 66, 0.08)'),
		width: '32px',
		height: '32px',
		opacity: '0',
		transition: 'all 0.5s ease-in-out',
		boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
		border: '1px solid #E0E3EA',
		'& span': {
			marginRight: '2px',
		},
		'&.minimized': {
			transform: 'rotate(180deg)',
		},
	},
	backContainer: {
		display: 'flex',
		gap: '8px',
		alignItems: 'center',
		padding: '10px 12px',
		cursor: 'pointer',
		transition: 'transform 0.4s',
		willChange: 'transform',
	},
	backIcon: {
		background: (props) => (props.variant == 'dark' ? '#fff' : 'rgba(38, 40, 66, 0.08)'),
		width: '28px',
		height: '28px',
		transition: 'transform 0.5s ease-in-out',
		'& span': {
			marginRight: '2px',
		},
		'&.minimized': {
			transform: 'rotate(180deg)',
		},
	},
	backTitle: {
		color: (props) => (props.variant == 'dark' ? '#fff' : '#262842'),
	},
	container: {
		borderRight: '1px solid rgba(38, 40, 66, 0.08)',
		display: 'flex',
		width: '248px',
		// width: '100%',
		flexDirection: 'column',
		transition: 'opacity 600ms ease-in-out, width 600ms ease-in-out, top 10ms ease-in-out',
		'&:hover > $logo > $minimizeIcon': {
			opacity: 1,
		},
		'&.mini': {
			width: '64px',
		},
	},
	logo: {
		padding: '14px 12px',
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
	},
	settingsTitle: {
		padding: '10px 12px 12px',
		color: (props) => (props.variant == 'dark' ? '#fff' : '#000000'),
		fontWeight: 500,
		fontSize: '16px',
		lineHeight: '18px',
	},
	link: {
		textDecoration: 'none',
		color: theme.palette.primary[200],
	},
	sideMenuWrapper: {
		height: 'calc(100% - 48px)',
	},
	disabledListItem: {
		position: 'relative',
	},
	lockIcon: {
		position: 'absolute',
		right: 0,
		top: '50%',
		transform: 'translate(0, -50%)',
		fontSize: 16,
		color: '#8B8C9E',
	},
}));

export default useStyles;
