import axios from 'axios';

import {
	CreateEmployeeShortInfo,
	CreateEmployeeVerifiedRequestDto,
	CurrentProfile,
	EmployeeInfo,
	EmployeeRegistrationStatus,
	EmployeeShortInfo,
	RegistrationStep,
} from '@dar/api-interfaces';

import { authService } from '../dar-dms-topbar';
import { environment } from '../environments/environment';
import { httpClient } from './clients';

export const createEmployeeShort = (data: CreateEmployeeShortInfo) => {
	return httpClient
		.post<EmployeeShortInfo>(`${environment().hcmsApiRoot}/main-api/employee/create/short`, data)
		.then((res) => res?.data);
};
export const createEmployeeShortVerified = (data: CreateEmployeeVerifiedRequestDto) => {
	return axios
		.post<EmployeeShortInfo>(`${environment().hcmsApiRoot}/main-api/employee/create/verified`, data)
		.then((res) => res?.data);
};
// !FIXME: remove below variable logic where it is used, deprecated
export const startRegistrationStatus = (data: EmployeeRegistrationStatus) => {
	return new Promise((resolve) => resolve(true));
};
// !FIXME: remove below variable logic where it is used, deprecated
export const updateRegistrationStatus = (id: string, registrationStep: RegistrationStep) => {
	return new Promise((resolve) => resolve(true));
};

export const getEmployeeItem = ({ profileId, companyId }: CurrentProfile) => {
	const workspaceToken = authService.getWorkspaceToken();
	return httpClient
		.get<EmployeeInfo>(`${environment().dmsBaseApi}/hcms/main-api/employee/${profileId}/shortinfo`, {
			params: {
				companyId,
			},
			headers: {
				'dar-dms-user-id': JSON.parse(localStorage.getItem('profile'))?.id,
				'dar-dms-org-id': JSON.parse(localStorage.getItem('workspace'))?.id,
				'Workspace-Authorization': workspaceToken,
			},
		})
		.then((res) => {
			return res.data;
		});
};

export const checkIfEmployeeExits = (profileId: string) => {
	return httpClient.get(`${environment().hcmsApiRoot}/main-api/employee/${profileId}/is-exist`).then((res) => res.data);
};
