import styled from '@emotion/styled';
import { Box } from '@material-ui/core';

import { Button } from '../components/shared/button/button';
import { Typography } from '../components/shared/typography/typography';
import { sidebarTheme } from '../sidebar-theme';

const Container = styled(Button)`
	border-top: ${sidebarTheme.border.default};
	width: 100%;
	height: var(--chat-bot-section-height, 58px);
	padding: ${sidebarTheme.spacing['positive-150']};
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	&:hover {
		background: #f7f7f8;
		cursor: pointer;
	}

	&:active {
		background: #eeeef0;
	}
`;

const Title = styled(Typography)`
	color: #262842;
	text-align: left;
`;

const SubTitle = styled(Typography)`
	color: #6D6E85;
	text-align: left;
`;

const TextContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	margin-left: ${sidebarTheme.spacing['positive-125']};
	will-change: opacity, display;
`;

export const StyledChatBotSection = {
  Container,
  Title,
  SubTitle,
	TextContainer,
};
